import React from 'react';
import './About.scss';
import '../../resources/StandardStyles.scss';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header';

class About extends React.Component{
    render(){
        return(
            <div className='standard'>
                <Header/>
                <div className='standard__first-section'>
                    <h2 className='standard__general-title'>Istoric</h2>
                    <h4 className='standard__general-description'>Legea asupra instrucţiunii" din noiembrie 1864 prevedea la articolul 184: "în toate oraşele unde sunt licee de băieţi, se vor stabili şcoli secundare de fete compuse din cinci clase".</h4>
                </div>
                <div className='about__container'>
                    <h3 className='standard__section-title'>Scurt Istoric</h3>
                    <img className='about__image' src='/images/presentation/istoric.jpg' alt='history'/>
                    <h3 className='about__section-subtitle'>1833</h3>
                    <h4 className='standard__general-description about__description'>Regulamentul scolar prevedea folosirea cuvintelor pur romanesti, de la baza limbii romane, in locul celor imprumutate, iar termenii tehnici, stiintifici, politici si din domeniul artei sa fie imprumutati din latina, italiana sau franceza.</h4>
                    <h3 className='about__section-subtitle'>1868</h3>
                    <h4 className='standard__general-description about__description'>Regulamentul scolar prevedea folosirea cuvintelor pur romanesti, de la baza limbii romane, in locul celor imprumutate, iar termenii tehnici, stiintifici, politici si din domeniul artei sa fie imprumutati din latina, italiana sau franceza.</h4>
                    <img className='about__image' src='/images/presentation/istoric.jpg' alt='history'/>
                    <h3 className='about__section-subtitle'>1890</h3>
                    <h4 className='standard__general-description about__description'>Regulamentul scolar prevedea folosirea cuvintelor pur romanesti, de la baza limbii romane, in locul celor imprumutate, iar termenii tehnici, stiintifici, politici si din domeniul artei sa fie imprumutati din latina, italiana sau franceza.</h4>
                    <img className='about__image' src='/images/presentation/istoric.jpg' alt='history'/>
                    <h3 className='about__section-subtitle'>1990</h3>
                    <h4 className='standard__general-description about__description'>Regulamentul scolar prevedea folosirea cuvintelor pur romanesti, de la baza limbii romane, in locul celor imprumutate, iar termenii tehnici, stiintifici, politici si din domeniul artei sa fie imprumutati din latina, italiana sau franceza.</h4>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default About;