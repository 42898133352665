import React from 'react';
import './UserAnnouncements.scss';
import '../../resources/StandardStyles.scss';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header';
import {announcementService} from '../../services/AnnouncementsService';
import {Link} from 'react-router-dom';

class UserAnnouncements extends React.Component{
    constructor(props){
        super(props)
        this.state={
           announcements:[]
            }
        }
    componentDidMount(){
        announcementService.getAll().then(response=>{
           let announcements=this.state.announcements;
            announcements=response.announcements;
            this.setState({announcements})
        })
        .catch(err=>console.log(err))
    }

    render(){

        return(
            <div className='standard announcements'>
             <Header/>
             <div className='standard__first-section'>
                    <h2 className='standard__general-title'>Anunturi</h2>
                </div>
                <div className='announcements__wrapper'>
                    <div className='announcements__container'>
                        {this.state.announcements.map(announcement=>(
                            <div className='announcements__card' key={announcement.id}>
                                <img src={process.env.REACT_APP_API_URL +'/'+announcement.document.url} className='announcements__image' alt='announcement'/>
                                <div className='announcements__card-information'>
                                    <h3 className='announcements__card-title'>{announcement.title}</h3>
                                    <div className='announcements__card-intro'>
                                        <h5>{announcement.date}</h5>
                                    </div>
                                    <p className='announcements__card-info'>{announcement.description}</p>
                                    <Link  to={`anunt/${announcement.id}`} className='standard__link' ><h4 className='announcements__see-doc'>VIZUALIZEAZA ANUNT</h4></Link>
                                    </div>
                            </div>
                        ))}
                    </div>
                </div>
             <Footer/>
            </div>
        )
    }
}
export default UserAnnouncements;