import React from 'react';
import './Documents.scss';
import '../../resources/StandardStyles.scss';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import documentsJson from './documents.json';
import {budgetService} from '../../services/BudgetService';

class FinancialData extends React.Component{
    constructor(props){
        super(props)
        this.state={
            documents:[],
            searchInfo:{
                document:'',
                documentType:'',
                documentDate:''
            }
        }
    }
    componentDidMount(){
        budgetService.getAll().then(response=>{
           let documents=response.budgets;
           console.log(response.data)
           this.setState({documents})
        })
        .catch(err=>console.log(err))
    }
    updateField=event=>{
        const field=event.target.name;
        const searchInfo={...this.state.searchInfo};
        searchInfo[field]=event.target.value;
        this.setState({searchInfo})
    }
    render(){
        let today = new Date().toISOString().substr(0, 10);
        return(
            <div className='standard documents'>
             <Header/>
             <div className='standard__first-section'>
                    <h2 className='standard__general-title'>Date financiare</h2>
                </div>
                <div className='documents__wrapper'>
                    <h2 className='documents__number'>Buget</h2>
                    <form className='documents__search-form'>
                    <div className='documents__search-input'>
                        <input
                            className="documents__field documents__date"
                            type="text"
                            name="document"
                            placeholder="Cauta document..."
                            onChange={this.updateField}
                            value={this.state.searchInfo.document}
                        />
                        <img src='/images/icons/search.png' alt='search'/>
                      </div>
                    <div className='documents__select-box'>
                        <select
                            className="documents__field documents__select"
                            title="tip"
                            value={this.state.searchInfo.documentType}
                            name="documentType"
                            onChange={this.updateField}
                            label='tip'
                            >
                                <option value="">Tip document</option>
                                <option value="1">1</option>
                                <option value="2">2</option>

                        </select>
                    </div>
                    <input
                        className="documents__field documents__date"
                        type="date"
                        name="documentDate"
                        placeholder="Type Date"
                        onChange={this.updateField}
                        value={this.state.searchInfo.documentDate}
                        defaultValue={today}
                      />
                    </form>
                    <div className='documents__container'>
                        {this.state.documents.map(document=>(
                            <div className='documents__card' key={document.id}>
                                <h3 className='documents__card-title'>{document.title}</h3>
                                <div className='documents__card-intro'>
                                    <h5>{document.date}</h5>
                                    <h5>{document.documentType}</h5>
                                </div>
                                <p className='documents__card-info'>{document.info}</p>
                                <h4 className='documents__see-doc'>VIZUALIZEAZA DOCUMENT</h4>
                            </div>
                        ))}
                    </div>
                </div>
             <Footer/>
            </div>
        )
    }
}
export default FinancialData;