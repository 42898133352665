import React from 'react';
import './Admission.scss';
import '../../resources/StandardStyles.scss';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header';
import {admissionService} from '../../services/AdmissionService';
import {linksService} from '../../services/LinksService';
import {Link} from 'react-router-dom';

class Admission extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          documents: [],
          pagination: '',
          links:[]
        };
      }
      componentWillMount() {
        admissionService
          .getAll()
          .then(jsonResponse => {
            console.log(jsonResponse);
            this.setAdmission(jsonResponse);
          })
          .catch(error => console.log(error));
          linksService
          .getAll()
          .then(jsonResponse => {
            console.log(jsonResponse);
            this.setLinks(jsonResponse);
          })
          .catch(error => console.log(error));
      }
      setAdmission = response => {
        let documents = { ...this.state.documents };
        let pagination = { ...this.state.pagination };
        documents = response.admissions;
        pagination = response.pagination;
        this.setState({ documents });
        this.setState({ pagination });
        console.log(this.state.documents);
      };
      setLinks=response=>{
        let links = {...this.state.links}
        links=response.usefull_links;
        this.setState({links})
      }
    render(){
        return(
            <div className='standard admission'>
            <Header/>
                <div className='standard__first-section'>
                    <h2 className='standard__general-title'>Admitere</h2>
                </div>
                <div className='admission__container'>
                    <h3 className='standard__section-title'>Oferta educațională</h3>
                    <div className='admission__individual-type'>
                    {this.state.documents.map(admission=>(
                         <div className='admission__individual' key={admission.id}>
                         <h3 className='admission__number' onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_API_URL}${
                                admission.document.url
                              }`
                            )
                          }>{admission.title}</h3>
                         <h4 className='admission__info'>{admission.date}</h4>
                         <p className='admission__description'>{admission.description}</p>
                     </div>
                    ))}
                    </div>
                </div>
                <div className='admission__opportunities'>
                    <h4 className='admission__subtitle'>Oportunitati si facilitati pentru elevi:</h4>
                    {this.state.links.map(link=>(
                        <ul className='admission__opportunities-list' key={link.id}>
                        <div className='admission__link-box'>
                            <a href={link.link} className='standard__link'> <li className='admission__info'>{link.title}</li></a>
                            {link.description? <h4>- {link.description}</h4> :''}
                        </div>
                        </ul>
                    ))}
                  
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Admission;