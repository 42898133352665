import React from 'react';
import './Menu.scss';
import { Link } from 'react-router-dom';
import menuJson from './menu.json';
import '../../resources/StandardStyles.scss';
import SubMenu from '../SubMenu/SubMenu';

class Menu extends React.Component{
    constructor(props){
        super()
        this.state={
            menu:menuJson,
            openMenuById: 0
        }
    }
    
  enterButton = id => {
      let openMenuById=this.state.openMenuById;
      openMenuById=id;
    this.setState({ openMenuById}, () => {
        console.log(this.state.openMenuById)

    });
    document.body.classList.add('has-active-menu');
  };

  leaveButton = () => {
    this.setState({ openMenuById: 0 });
    document.body.classList.remove('has-active-menu');
  };
    render(){
        return(
            <div className='menu'>
                <ul className='menu__links'>
                    {this.state.menu.map(menuItem=>(
                        <li key={menuItem.id}>
                        {menuItem.submenu ? (
                        <div
                            onMouseEnter={() => this.enterButton(menuItem.id)}
                            onMouseLeave={this.leaveButton}
                            className='standard__link'
                        >
                            <span className='menu__item'>{menuItem.name}</span>
                            <SubMenu
                            menu={menuItem}
                            buttonHovered={this.state.openMenuById}
                            closeMenu={this.leaveButton}
                            />
                        </div>
                        ) : (
                        <Link
                            to={menuItem.url}
                            onClick={() => this.leaveButton()}
                            className='standard__link menu__item'
                        >
                            {menuItem.name}
                        </Link>
                        )}
                    </li>
                        // <Link to={item.url} className='standard__link'><li key={item.id} className='menu__item'>{item.name}</li></Link>
                    ))}
                </ul>
            </div>
        )
    }
}

export default Menu;