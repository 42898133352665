import React from 'react';
import './Lists.scss';
import '../../resources/StandardStyles.scss';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import listJson from './list.json';
import {studentsCouncilService} from '../../services/StudentsCouncilService';

class StudentsCouncil extends React.Component{
    constructor(props){
        super(props)
        this.state={
            list:[]
        }
    }
    componentDidMount(){
        studentsCouncilService.getAll().then(response=>{
           let list=response.students_boards;
           console.log(response.data)
           this.setState({list})
        })
        .catch(err=>console.log(err))
    }
    render(){
        return(
            <div className='standard list'>
            <Header/>
            <div className='standard__first-section'>
                <h2 className='standard__general-title'>Consiliul Elevilor</h2>
                {/* <h4 className='standard__general-description'>Legea asupra instrucţiunii" din noiembrie 1864 prevedea la articolul 184: "în toate oraşele unde sunt licee de băieţi, se vor stabili şcoli secundare de fete compuse din cinci clase".</h4> */}
            </div>
            <div className='list__container'>
                <h3 className='standard__section-title'>Membri Consiliul Elevilor</h3>
                {this.state.list.map(item=>(
                    <div className='list__individual' key={item.key}>
                        <h3 className='list__person'>{item.name}</h3>
                        <h3 className='list__function'>{item.position}</h3>
                        <h3
                            className="list__see-doc"
                            onClick={() =>
                            window.open(
                                `${process.env.REACT_APP_API_URL}${
                                item.document.url
                                }`
                            )
                            }
                        >
                            Vizualizează Document
                        </h3>
                        <h3
                            className="list__see-doc"
                            onClick={() =>
                            window.open(
                                `${process.env.REACT_APP_API_URL}${
                                item.document_decision.url
                                }`
                            )
                            }
                        >
                            Vizualizează Decizia
                        </h3>
                    </div>
                ))}
            </div>
            <Footer/>
        </div>
        )
    }
}
export default StudentsCouncil;