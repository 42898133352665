import React from 'react';
import './Organigram.scss';
import organigramJson from './Organigram.json';
import '../../resources/StandardStyles.scss';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header';

class Organgram extends React.Component{
    constructor(props){
        super(props)
        this.state={
            organigram:organigramJson
        }
    }
    render(){
        return(
            <div className='standard organigram'>
                <Header/>
                <div className='standard__first-section'>
                    <h2 className='standard__general-title'>Organizare</h2>
                    <h4 className='standard__general-description'>Legea asupra instrucţiunii" din noiembrie 1864 prevedea la articolul 184: "în toate oraşele unde sunt licee de băieţi, se vor stabili şcoli secundare de fete compuse din cinci clase".</h4>
                </div>
                <div className='organigram__container'>
                    <h3 className='standard__section-title'>Consiliul de Administratie</h3>
                    {this.state.organigram.map(item=>(
                        <div className='organigram__individual' key={item.key}>
                            <h3 className='organigram__person'>{item.name}</h3>
                            <h3 className='organigram__function'>{item.function}</h3>
                        </div>
                    ))}
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Organgram;