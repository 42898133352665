import React from 'react';
import './Results.scss';
import '../../resources/StandardStyles.scss';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header';
import { Link } from 'react-router-dom';
import {resultService} from '../../services/ResultService';

class Results extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          documents: [],
          pagination: ''
        };
      }
      componentWillMount() {
        resultService
          .getAll()
          .then(jsonResponse => {
            console.log(jsonResponse);
            this.setResults(jsonResponse);
          })
          .catch(error => console.log(error));
      }
      setResults = response => {
        let documents = { ...this.state.documents };
        let pagination = { ...this.state.pagination };
        documents = response.results;
        pagination = response.pagination;
    
        this.setState({ documents });
        this.setState({ pagination });
    
        console.log(this.state.documents);
      };
    render(){
        return(
            <div className='standard results'>
            <Header/>
                <div className='standard__first-section'>
                    <h2 className='standard__general-title'>Rezultate</h2>
                </div>
                <div className='results__container'>
                    <h3 className='standard__section-title'></h3>
                    {this.state.documents.map(result=>(
                        <div key={result.id} onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_API_URL}${
                                result.document.url
                              }`
                            )
                          }>
                            <div className='results__box'>
                            <img src='/images/icons/link-blue.png' alt='link'/>
                                <h3 className='results__subtitle'>{result.title}</h3>
                            </div>
                            <h4 className='standard__general-description results__description'>{result.description}</h4>
                        </div>
                    ))}
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Results;