import React from 'react';
import AdminMenu from '../../../components/AdminMenu/AdminMenu';
import AttachementField from '../../../components/AttachementField/AttachementField';
import '../../../resources/adminStyle.scss';
import {managerialDocumentsService} from '../../../services/ManagerialDocumentsService';
import NotificationSystem from 'react-notification-system';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AuthenticationService from '../../../services/AuthenticationService';

class AdminManagerialDocuments extends React.Component{
    constructor(props){
        super(props)
        this.state={
           newDocument:{
            title:'',
            date:'',
            document:''
           },
           existingDocuments:[],
           deleteDocument: {
            openedDialog: false,
            deleteId: 0
          },
          pagination: {}
        }
        this.authentication = new AuthenticationService();
    }
    handleChange=event=>{
        let newDocument = { ...this.state.newDocument };
        newDocument[event.target.name] = event.target.value;

          this.setState({ newDocument });
      }
      componentWillMount() {
        managerialDocumentsService
          .getAll()
          .then(response => {
            this.setDocumentState(response);
          })
          .catch(error => {
            console.log(error);
          });
      }

      componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
      }
      setDocumentState = response => {
        let existingDocuments = this.state.existingDocuments;
        existingDocuments = response.managerial_documents;
        this.setState({
            existingDocuments
        });
        let pagination = { ...this.state.pagination };
        pagination = response.pagination;
        this.setState({
          pagination
        });
      };
      handlePageClick = currentPage => {
        managerialDocumentsService
          .getAll(currentPage.selected + 1)
          .then(response => {
            this.setDocumentState(response);
          })
          .catch(error => console.log(error));
      };
      resetForm = () => {
        let newDocument = { ...this.state.newDocument };
        newDocument = {
          title: '',
          date:'',
          description:'',
          document: ''
        };
        this.setState({ newDocument });
        this.setState({ disabledDocument: false });
      };
      openDeleteDialog = id => {
        let deleteDocument = { ...this.state.deleteDocument };
        deleteDocument = {
          openedDialog: true,
          deleteId: id
        };

        this.setState({ deleteDocument });
      };

      closeDeleteDialog = () => {
        let deleteDocument = { ...this.state.deleteDocument };
        deleteDocument = {
          openedDialog: false,
          deleteId: 0
        };

        this.setState({ deleteDocument });
      };
      handleFormSubmit = event => {
        event.preventDefault();

        managerialDocumentsService
          .createOrUpdate(this.state.newDocument)
          .then(response => {

            let existingDocuments = this.state.existingDocuments;

            let index = existingDocuments.findIndex(
              existingDocuments => existingDocuments.id === response.id
            );

            if (index !== -1) {
              existingDocuments[index] = response;
            } else {
              existingDocuments.push(response);
            }

            this.setState({
              existingDocuments
            });

            this._notificationSystem.addNotification({
              message: `Documentul a fost ${
                this.state.newDocument.id ? 'editat' : 'adaugat'
              } cu success.`,
              level: 'success',
              position: 'tc'
            });

            this.resetForm();
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problemă la adăugare, faceti refresh la pagina sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
      onDropDocument = documents => {
        let newDocument = { ...this.state.newDocument };
        let reader = new FileReader();
        let self = this;

        reader.onload = function(upload) {
          newDocument.document = documents[0];

          self.setState({ newDocument });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };

      removeDocument = () => {
        let newDocument = { ...this.state.newDocument };
        newDocument.document = null;

        this.setState({ newDocument });
        this.setState({ disabledDocument: false });
      };

      setManagerialDocumentForEdit = (event, document) => {
          event.preventDefault();

        let newDocument = { ...this.state.newDocument };
        newDocument = Object.assign({}, document);
        this.setState({ newDocument });

        if (newDocument.document && newDocument.document.url) {
          this.setState({ disabledDocument: true });
        }
      };

      handleDelete = () => {
        managerialDocumentsService
          .deleteDocument(this.state.deleteDocument.deleteId)
          .then(() => {
            let existingDocuments = this.state.existingDocuments.slice();
            existingDocuments = existingDocuments.filter(
                document => document.id !== this.state.deleteDocument.deleteId
            );
            this.setState({ existingDocuments });

            this.closeDeleteDialog();

            this._notificationSystem.addNotification({
              message: 'Documentul a fost șters cu success.',
              level: 'success',
              position: 'tc'
            });
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problema la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
    render(){
        return(
            <div className='admin'>
                <AdminMenu/>
                <div className='admin__wrapper'>
                    <h2 className='admin__title'>Documente Manageriale</h2>
                    <h3 className='admin__subtitle'>Adaugă un document:</h3>
                        <form className='admin__form' onSubmit={this.handleFormSubmit}>
                            <h4 className='admin__label'>Titlu document:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="title"
                                onChange={this.handleChange}
                                required
                                value={this.state.newDocument.title}/>
                            <h4 className='admin__label'>Data:</h4>
                            <input
                                className="admin__field"
                                type="date"
                                name="date"
                                required
                                onChange={this.handleChange}
                                value={this.state.newDocument.date}/>
                            <div className='admin__attachement'>
                                <AttachementField
                                onDrop={this.onDropDocument}
                                removeDocument={this.removeDocument}
                                document={this.state.newDocument.document}
                                documentFilename={
                                    this.state.newDocument.document_filename
                                }
                                type="image/*"
                                />
                            </div>
                            <button type='submit' className='admin__submit-button'> {this.state.newDocument.id
                        ? 'Editează'
                        : 'Adaugă'} documentul</button>
                        </form>
                        <div className='admin__existing-documents'>
                        {this.state.existingDocuments && this.state.existingDocuments.length?  <h3 className='admin__no-documents'> Documente existente:</h3> : ''}
                        {this.state.existingDocuments && this.state.existingDocuments.length?
                        <div className='admin__existing-docs-wrapper'>
                         {this.state.existingDocuments.map(document=>
                            ( <div className='admin__card' key={document.id}>
                                 <h3 className='admin__card-title'>{document.title}</h3>
                                 <div className='admin__date-box'>
                                    <img src='/images/icons/calendar.png' alt='calendar'/>
                                    <h4 className='admin__card-date'>{document.date}</h4>
                                 </div>
                                 <p className='admin__card-description'>{document.description}</p>
                                 <div className='admin__document-box'  onClick={() =>
                                    window.open(
                                        `${process.env.REACT_APP_API_URL}${
                                        document.document.url
                                        }`
                                    )
                                    }>
                                     <img src='/images/icons/file.png' alt='file'/>
                                     <h3 className='admin__document-name'>Vezi document</h3>
                                 </div>
                                 <div className='admin__actions'>
                                    <h4 className='admin__edit' onClick={event => this.setManagerialDocumentForEdit(event, document)} >Editează</h4>
                                    <h4 className='admin__delete'  onClick={() => this.openDeleteDialog(document.id)}>Șterge</h4>
                                 </div>
                             </div>)
                         )}
                         </div>:
                        <h3 className='admin__no-documents'> Nu sunt documente adăugate.</h3>
                    }


                        </div>
                </div>
                <NotificationSystem
                    ref="notificationSystem"
                    style={NotificationSystemStyle}
                    />
                 <DeletePUGCertificates
                    opened={this.state.deleteDocument.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur că doriti să ștergeti acest document?"
                    aditionalText="Dupa ștergere, documentul nu va mai putea fi recuperat."
                  />
            </div>
        )
    }
}
export default AdminManagerialDocuments;
