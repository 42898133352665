import React from 'react';
import AdminMenu from '../../../components/AdminMenu/AdminMenu';
import AttachementField from '../../../components/AttachementField/AttachementField';
import '../../../resources/adminStyle.scss';
import {magazineService} from '../../../services/MagazineService';
import NotificationSystem from 'react-notification-system';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AuthenticationService from '../../../services/AuthenticationService';

class Magazine extends React.Component{
    constructor(props){
        super(props)
        this.state={
           newMagazine:{
            title:'',
            date:'',
            document:''
           },
           existingMagazines:[],
           deleteMagazine: {
            openedDialog: false,
            deleteId: 0
          },
          pagination: {}
        }
        this.authentication = new AuthenticationService();
    }
    handleChange=event=>{
        let newMagazine = { ...this.state.newMagazine };
        newMagazine[event.target.name] = event.target.value;
  
          this.setState({ newMagazine });
      }
 
    
      componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        magazineService
        .getAll()
        .then(response => {
          this.setMagazineState(response);
        })
        .catch(error => {
          console.log(error);
        });
      }
      setMagazineState = response => {
        let existingMagazines = this.state.existingMagazines;
        existingMagazines = response.magazines;
        this.setState({
            existingMagazines
        });
        let pagination = { ...this.state.pagination };
        pagination = response.pagination;
        this.setState({
          pagination
        });
      };
      handlePageClick = currentPage => {
        magazineService
          .getAll(currentPage.selected + 1)
          .then(response => {
            this.setMagazineState(response);
          })
          .catch(error => console.log(error));
      };
      resetForm = () => {
        let newMagazine = { ...this.state.newMagazine };
        newMagazine = {
          title: '',
          date:'',
          document: ''
        };
        this.setState({ newMagazine });
        this.setState({ disabledDocument: false });
      };
      openDeleteDialog = id => {
        let deleteMagazine = { ...this.state.deleteMagazine };
        deleteMagazine = {
          openedDialog: true,
          deleteId: id
        };
    
        this.setState({ deleteMagazine });
      };
    
      closeDeleteDialog = () => {
        let deleteMagazine = { ...this.state.deleteMagazine };
        deleteMagazine = {
          openedDialog: false,
          deleteId: 0
        };
    
        this.setState({ deleteMagazine });
      };
      handleFormSubmit = event => {
        event.preventDefault();
    
        magazineService
          .createOrUpdate(this.state.newMagazine)
          .then(response => {
    
            let existingMagazines = this.state.existingMagazines;
    
            let index = existingMagazines.findIndex(
              existingMagazines => existingMagazines.id === response.id
            );
    
            if (index !== -1) {
              existingMagazines[index] = response;
            } else {
              existingMagazines.push(response);
            }
    
            this.setState({
              existingMagazines
            });
    
            this._notificationSystem.addNotification({
              message: `Revista a fost ${
                this.state.newMagazine.id ? 'editata' : 'adaugata'
              } cu success.`,
              level: 'success',
              position: 'tc'
            });

            this.resetForm();
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problemă la adăugare, faceti refresh la pagina sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
      onDropDocument = documents => {
        let newMagazine = { ...this.state.newMagazine };
        let reader = new FileReader();
        let self = this;
    
        reader.onload = function(upload) {
          newMagazine.document = documents[0];
    
          self.setState({ newMagazine });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };
    
      removeDocument = () => {
        let newMagazine = { ...this.state.newMagazine };
        newMagazine.document = null;
    
        this.setState({ newMagazine });
        this.setState({ disabledDocument: false });
      };
    
      setMagazineForEdit = (event, magazine) => {
          event.preventDefault();

        let newMagazine = { ...this.state.newMagazine };
        newMagazine = Object.assign({}, magazine);
        this.setState({ newMagazine });
    
        if (newMagazine.document && newMagazine.document.url) {
          this.setState({ disabledDocument: true });
        }
      };
    
      handleDelete = () => {
        magazineService
          .deleteMagazine(this.state.deleteMagazine.deleteId)
          .then(() => {
            let existingMagazines = this.state.existingMagazines.slice();
            existingMagazines = existingMagazines.filter(
                magazine => magazine.id !== this.state.deleteMagazine.deleteId
            );
            this.setState({ existingMagazines });
    
            this.closeDeleteDialog();
    
            this._notificationSystem.addNotification({
              message: 'Revista a fost șters cu success.',
              level: 'success',
              position: 'tc'
            });
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problema la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
  
    render(){
        return(
            <div className='admin'>
                <AdminMenu/>
                <div className='admin__wrapper'>
                    <h2 className='admin__title'>Reviste școlare</h2>
                    <h3 className='admin__subtitle'>Adaugă o revistă:</h3>
                        <form className='admin__form' onSubmit={this.handleFormSubmit}>
                            <h4 className='admin__label'>Titlu revista:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="title"
                                onChange={this.handleChange}
                                required
                                value={this.state.newMagazine.title}/>
                            <h4 className='admin__label'>Data:</h4>
                            <input
                                className="admin__field"
                                type="date"
                                name="date"
                                required
                                onChange={this.handleChange}
                                value={this.state.newMagazine.date}/>
                            <div className='admin__attachement'>
                                <AttachementField
                                onDrop={this.onDropDocument}
                                removeDocument={this.removeDocument}
                                document={this.state.newMagazine.document}
                                documentFilename={
                                    this.state.newMagazine.document_filename
                                }
                                type="image/*"
                                />
                            </div>
                            <button type='submit' className='admin__submit-button'> {this.state.newMagazine.id
                        ? 'Editează'
                        : 'Adaugă'} revista</button>       
                        </form>
                        <div className='admin__existing-documents'>
                        {this.state.existingMagazines && this.state.existingMagazines.length?  <h3 className='admin__no-documents'> Documente existente:</h3> : ''} 
                        {this.state.existingMagazines && this.state.existingMagazines.length? 
                        <div className='admin__existing-docs-wrapper'>
                         {this.state.existingMagazines.map(magazine=>
                            ( <div className='admin__card'>
                                 <h3 className='admin__card-title'>{magazine.title}</h3>
                                 <div className='admin__date-box'>
                                    <img src='/images/icons/calendar.png' alt='calendar'/>
                                    <h4 className='admin__card-date'>{magazine.date}</h4>
                                 </div>
                                 <p className='admin__card-description'>{magazine.description}</p>
                                 <div className='admin__document-box'  onClick={() =>
                                    window.open(
                                        `${process.env.REACT_APP_API_URL}${
                                        magazine.document.url
                                        }`
                                    )
                                    }>
                                     <img src='/images/icons/file.png' alt='file'/>
                                     <h3 className='admin__document-name'>Vezi document</h3>
                                 </div>
                                 <div className='admin__actions'>
                                    <h4 className='admin__edit' onClick={event => this.setMagazineForEdit(event, magazine)} >Editează</h4>
                                    <h4 className='admin__delete'  onClick={() => this.openDeleteDialog(magazine.id)}>Șterge</h4>
                                 </div>
                             </div>)
                         )}
                         </div>: 
                        <h3 className='admin__no-documents'> Nu sunt documente adăugate.</h3>
                    }
                       
                               
                        </div>
                </div>
                
                <NotificationSystem
                    ref="notificationSystem"
                    style={NotificationSystemStyle}
                    />
                 <DeletePUGCertificates
                    opened={this.state.deleteMagazine.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur că doriti să ștergeti aceasta revista?"
                    aditionalText="Dupa ștergere, revista nu va mai putea fi recuperata."
                  />
            </div>
        )
    }
}
export default Magazine;