import React from 'react';
import AdminMenu from '../../../components/AdminMenu/AdminMenu';
import AttachementField from '../../../components/AttachementField/AttachementField';
import '../../../resources/adminStyle.scss';
import {vacantPostsService} from '../../../services/VacantPostsService';
import NotificationSystem from 'react-notification-system';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AuthenticationService from '../../../services/AuthenticationService';

class VacantPosts extends React.Component{
    constructor(props){
        super(props)
        this.state={
           newPost:{
            title:'',
            date:'',
            description:'',
            document:''
           },
           existingPosts:[],
           deletePost: {
            openedDialog: false,
            deleteId: 0
          },
          pagination: {}
        }
        this.authentication = new AuthenticationService();
    }
    handleChange=event=>{
        let newPost = { ...this.state.newPost };
        newPost[event.target.name] = event.target.value;
  
          this.setState({ newPost });
      }

    
      componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        vacantPostsService
        .getAll()
        .then(response => {
          this.setPostState(response);
        })
        .catch(error => {
          console.log(error);
        });
      }
      setPostState = response => {
        let existingPosts = this.state.existingPosts;
        existingPosts = response.vacant_posts;
        this.setState({
            existingPosts
        });
        let pagination = { ...this.state.pagination };
        pagination = response.pagination;
        this.setState({
          pagination
        });
      };
      handlePageClick = currentPage => {
        vacantPostsService
          .getAll(currentPage.selected + 1)
          .then(response => {
            this.setPostState(response);
          })
          .catch(error => console.log(error));
      };
      resetForm = () => {
        let newPost = { ...this.state.newPost };
        newPost = {
          title: '',
          date:'',
          description:'',
          document: ''
        };
        this.setState({ newPost });
        this.setState({ disabledDocument: false });
      };
      openDeleteDialog = id => {
        let deletePost = { ...this.state.deletePost };
        deletePost = {
          openedDialog: true,
          deleteId: id
        };
    
        this.setState({ deletePost });
      };
    
      closeDeleteDialog = () => {
        let deletePost = { ...this.state.deletePost };
        deletePost = {
          openedDialog: false,
          deleteId: 0
        };
    
        this.setState({ deletePost });
      };
      handleFormSubmit = event => {
        event.preventDefault();
    
        vacantPostsService
          .createOrUpdate(this.state.newPost)
          .then(response => {
    
            let existingPosts = this.state.existingPosts;
    
            let index = existingPosts.findIndex(
              existingPosts => existingPosts.id === response.id
            );
    
            if (index !== -1) {
              existingPosts[index] = response;
            } else {
              existingPosts.push(response);
            }
    
            this.setState({
              existingPosts
            });
    
            this._notificationSystem.addNotification({
              message: `Postul vacant a fost ${
                this.state.newPost.id ? 'editat' : 'adaugat'
              } cu success.`,
              level: 'success',
              position: 'tc'
            });

            this.resetForm();
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problemă la adăugare, faceti refresh la pagina sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
      onDropDocument = documents => {
        let newPost = { ...this.state.newPost };
        let reader = new FileReader();
        let self = this;
    
        reader.onload = function(upload) {
          newPost.document = documents[0];
    
          self.setState({ newPost });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };
    
      removeDocument = () => {
        let newPost = { ...this.state.newPost };
        newPost.document = null;
    
        this.setState({ newPost });
        this.setState({ disabledDocument: false });
      };
    
      setPostForEdit = (event, post) => {
          event.preventDefault();

        let newPost = { ...this.state.newPost };
        newPost = Object.assign({}, post);
        this.setState({ newPost });
    
        if (newPost.document && newPost.document.url) {
          this.setState({ disabledDocument: true });
        }
      };
    
      handleDelete = () => {
        vacantPostsService
          .deletePost(this.state.deletePost.deleteId)
          .then(() => {
            let existingPosts = this.state.existingPosts.slice();
            existingPosts = existingPosts.filter(
                post => post.id !== this.state.deletePost.deleteId
            );
            this.setState({ existingPosts });
    
            this.closeDeleteDialog();
    
            this._notificationSystem.addNotification({
              message: 'Postul vacant a fost șters cu success.',
              level: 'success',
              position: 'tc'
            });
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problema la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
  
    render(){
        return(
            <div className='admin'>
                <AdminMenu/>
                <div className='admin__wrapper'>
                    <h2 className='admin__title'>Posturi vacante</h2>
                    <h3 className='admin__subtitle'>Adaugă un anunț pentru ocuparea unui post vacant:</h3>
                        <form className='admin__form' onSubmit={this.handleFormSubmit}>
                            <h4 className='admin__label'>Titlu:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="title"
                                onChange={this.handleChange}
                                required
                                value={this.state.newPost.title}/>
                            <h4 className='admin__label'>Dată:</h4>
                            <input
                                className="admin__field"
                                type="date"
                                name="date"
                                required
                                onChange={this.handleChange}
                                value={this.state.newPost.date}/>
                             <h4 className='admin__label'>Detaliere:</h4>
                            <textarea
                                rows='5'
                                cols='10'
                                className="admin__field admin__textarea"
                                type="text"
                                name="description"
                                onChange={this.handleChange}
                                value={this.state.newPost.description}/>
                            <div className='admin__attachement'>
                                <AttachementField
                                onDrop={this.onDropDocument}
                                removeDocument={this.removeDocument}
                                document={this.state.newPost.document}
                                documentFilename={
                                    this.state.newPost.document_filename
                                }
                                type="image/*"
                                />
                            </div>
                            <button type='submit' className='admin__submit-button'> {this.state.newPost.id
                        ? 'Editează'
                        : 'Adaugă'}  post:</button>       
                        </form>
                        <div className='admin__existing-documents'>
                        {this.state.existingPosts && this.state.existingPosts.length?  <h3 className='admin__no-documents'> Documente existente:</h3> : ''} 
                        {this.state.existingPosts && this.state.existingPosts.length? 
                        <div className='admin__existing-docs-wrapper'>
                         {this.state.existingPosts.map(post=>
                            ( <div className='admin__card'>
                                 <h3 className='admin__card-title'>{post.title}</h3>
                                 <div className='admin__date-box'>
                                    <img src='/images/icons/calendar.png' alt='calendar'/>
                                    <h4 className='admin__card-date'>{post.date}</h4>
                                 </div>
                                 <p className='admin__card-description'>{post.description}</p>
                                 <div className='admin__document-box'  onClick={() =>
                                    window.open(
                                        `${process.env.REACT_APP_API_URL}${
                                        post.document.url
                                        }`
                                    )
                                    }>
                                     <img src='/images/icons/file.png' alt='file'/>
                                     <h3 className='admin__document-name'>Vezi document</h3>
                                 </div>
                                 <div className='admin__actions'>
                                    <h4 className='admin__edit' onClick={event => this.setPostForEdit(event, post)} >Editează</h4>
                                    <h4 className='admin__delete'  onClick={() => this.openDeleteDialog(post.id)}>Șterge</h4>
                                 </div>
                             </div>)
                         )}
                         </div>: 
                        <h3 className='admin__no-documents'> Nu sunt documente adăugate.</h3>
                    }
                       
                               
                        </div>
                </div>
                <NotificationSystem
                    ref="notificationSystem"
                    style={NotificationSystemStyle}
                    />
                 <DeletePUGCertificates
                    opened={this.state.deletePost.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur că doriti să ștergeti acest post?"
                    aditionalText="Dupa ștergere, postul nu va mai putea fi recuperat."
                  />
            </div>
        )
    }
}
export default VacantPosts;