import React from 'react';
import '../../resources/StandardStyles.scss';
import scheduleJson from './Schedule.json';

class Schedule extends React.Component{
    constructor(props){
        super(props)
        this.state={
            schedule:scheduleJson
        }
    }
    render(){
        return(
            <div className='standard__schedule-container'>
                    {
                        this.state.schedule.map(item=>(
                            <div key={item.id} className='standard__schedule-box'>
                                <h3 className='standard__schedule-title'>{item.day}</h3>
                                    {item.materies.map(classItem=>(
                                        <div key={classItem.id} className='standard__schedule-info'>
                                            <h4 className='standard__schedule-hour'>{classItem.hour}</h4>
                                            <h4 className='standard__schedule-class'>{classItem.class}</h4>
                                        </div>
                                    ))}
                            </div>
                        ))
                    }
            </div>
        )
    }
}

export default Schedule;