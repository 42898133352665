import React from 'react';
import { Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import menuJson from './menu.json';
import './AdminMenu.scss';
const { SubMenu } = Menu;

class AdminMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: menuJson,
            currentLocation: '/'
        };
    }

    componentDidMount() {
        // Set initial location
        this.updateLocation();
    }

    componentDidUpdate(prevProps) {
        // Update location when props change
        if (prevProps.location !== this.props.location) {
            this.updateLocation();
        }
    }

    updateLocation() {
        const { pathname } = this.props.location;
        this.setState({ currentLocation: pathname });
    }

    handleSubMenuClick = (subMenuId) => {
        this.setState({ openSubMenuId: subMenuId });
    };

    render() {
        const { currentLocation, menu } = this.state;

        return (
            <div className="amenu">
                <Link to='/' className='standard__link'>
                    <div className='amenu__logo-box'>
                        <img className='amenu__logo' src='/images/presentation/sigla.png' alt='sigla scolii' />
                        <div className='amenu__name-box'>
                            <h5 className='amenu__general-name'>Școala Gimnazială </h5>
                            <h4 className='amenu__specific-name'>  ”Dimitrie Vatamaniuc” Sucevița</h4>
                        </div>
                    </div>
                </Link>
                <Menu mode="inline" theme="dark" className='amenu__container' selectedKeys={[currentLocation]}>
                    <Menu.Item key="/">

                    </Menu.Item>
                    {menu.map(menuItem => (
                        menuItem.submenu && menuItem.submenu.length > 0 ? (
                            <SubMenu
                                key={menuItem.id}
                                title={
                                    <span className='amenu__link'>
                                        {menuItem.item}
                                    </span>
                                }
                            >
                                {menuItem.submenu.map(submenuItem => (
                                    <Menu.Item key={submenuItem.url}>
                                        <Link to={submenuItem.url} className='standard__link'>
                                            {submenuItem.name}
                                        </Link>
                                    </Menu.Item>
                                ))}
                            </SubMenu>
                        ) : (
                            <Menu.Item key={menuItem.url}>
                                <Link to={menuItem.url} className='standard__link'>
                                    <span className='amenu__link'>
                                        {menuItem.item}
                                    </span>
                                </Link>
                            </Menu.Item>
                        )
                    ))}
                </Menu>
            </div>
        );
    }
}

export default withRouter(AdminMenu);