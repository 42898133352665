import React from 'react';
import '../../resources/StandardStyles.scss';
import './ProfGrades.scss';
import profGrades from './professorGrades.json';
import { Link } from 'react-router-dom';

class ProfGrades extends React.Component{
    constructor(props){
        super(props);

        this.state={
            classType:"",
            grades:profGrades
        }
    }
    handleChange=event=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    }
    render(){
        return(
            <div className='prof-grades'>                        
                <form className='grades__form'>
                    <div className='grades__search-box'>
                        <input 
                        className='grades__input'
                        name="classType"
                        onChange={this.handleChange}
                        required
                        value={this.state.classType}
                        onChange={this.handleChange}
                        placeholder="Caută clasa"/>
                        <img className='grades__search-icon' src='/images/icons/search.svg' alt='search-icon'/>
                    </div>
                </form>
                <div className='prof-grades__wrapper'>
                {this.state.grades.map(grade=>(
                    <div className='prof-grades__individual-class' key={grade.id}>
                        <Link to="/admin/note-clasa"><h3 className='prof-grades__class-name'>{grade.grade}</h3></Link>
                    </div>
                ))}
                </div>
            </div>
        )
    }
}

export default ProfGrades;