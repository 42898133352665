import React from 'react';
import './LogIn.scss';
import '../../../resources/StandardStyles.scss';
import { loginService } from '../../../services/LoginService';
import {Link} from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';

class LogIn extends React.Component{
    constructor(props){
        super(props);

        this.state={
            email:"",
            password:""
        }
        this.notificationSystem = React.createRef();
    }
    handleChange=event=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    }
    componentDidMount() {
      this._notificationSystem = this.refs.notificationSystem;
    }

      handleLogin = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;

        loginService
          .login(this.state)
          .then(response => {
              console.log(response.headers)
            loginService.setToken(response.headers.authorization);
            this.props.history.push('/admin/buget');
          })
          .catch(error => {
            notification.addNotification({
              message: 'Email sau parola incorecte.',
              level: 'error',
              position: 'tc'
            });
          });
      }

    render(){
        return(
            <div className=' standard login'>
                <div className='login__logo-box'>
                    <img className='login__logo' src='/images/presentation/sigla.png' alt='sigla scolii'/>
                    <div className='login__name-box'>
                        <h4 className='login__general-name'> Școala Gimnazială</h4>
                        <h3 className='login__specific-name'> ”Dimitrie Vatamaniuc” Sucevița</h3>
                    </div>
                </div>
                    <div className='login__container'>
                        <h3 className='standard__section-title login__title'>Acces Platformă Școală</h3>
                        <form className='login__form' onSubmit={this.handleLogin}>
                              <input
                                className='login__input'
                                type="email"
                                name="email"
                                onChange={this.handleChange}
                                required
                                value={this.state.email}
                                onChange={this.handleChange}
                                placeholder="Email"/>

                                <input
                                className='login__input'
                                type="password"
                                name="password"
                                onChange={this.handleChange}
                                required
                                value={this.state.password}
                                placeholder="Parola"/>
                            <div className='login__submit-box'>
                                <button type='submit' className='login__submit-button'><h5 className='login__button-writing'>INTRA IN PLATFORMA</h5></button>
                            </div>
                        </form>

                    </div>
                    <NotificationSystem
                      ref={this.notificationSystem}
                      style={NotificationSystemStyle}
                    />
            </div>
        )
    }
}

export default LogIn;
