import axios from 'axios';


export const contactService={
    sendContactMessage
}

function sendContactMessage(contactData){
    const requestOptionsPost = {
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json'
        }
      };

      let url = `${process.env.REACT_APP_API_URL}/api/contacts`;


      return axios.post(url, contactData, requestOptionsPost);
}
