import React, { Component, useCallback } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import './App.scss';
import Homepage from './containers/Homepage/Homepage';
import About from './containers/About/About';
import Organigram from './containers/Organigram/Organigram.jsx';
import Values from './containers/Values/Values.jsx';
import Results from './containers/Results/Results';
import Projects from './containers/Projects/Projects';
import Admission from './containers/Admission/Admission';
import News from './containers/News/News';
import Announcement from './containers/Announcement/Announcement';
import Contact from './containers/Contact/Contact';
import LogIn from './containers/Admin/LogIn/LogIn';
import ScheduleAndGrades from './containers/Admin/ScheduleAndGrades/ScheduleAndGrades';
import ProfessorInfo from './containers/ProfessorInfo/ProfessorInfo';
import SpecificClass from './components/SpecificClass/SpecificClass.jsx';
import Budget from './containers/Admin/Budget/Budget';
import UsefulLinks from './containers/Admin/UsefulLinks/UsefulLinks';
import NationalEvaluation from './containers/Admin/NationalEvaluation/NationalEvaluation';
import Magazines from './containers/Admin/Magazines/Magazines';
import Regulaments from './containers/Admin/Regulaments/Regulaments';
import CouncilDecisions from './containers/Admin/CouncilDecisions/CouncilDecisions';
import AdminProjects from './containers/Admin/AdminProjects/AdminProjects';
import Announcements from './containers/Admin/Announcements/Announcements';
import VacantPosts from './containers/Admin/VacantPosts/VacantPosts';
import AdminResults from './containers/Admin/AdminResults/AdminResults';
import AdminAdmission from './containers/Admin/Admission/AdminAdmission';
import DecisionsCA from './containers/Documents/DecisionsCA';
import ManagerialDocuments from './containers/Documents/ManagerialDocuments';
import FinancialData from './containers/Documents/FinancialData';
import ViewRegulaments from './containers/Documents/Regulaments';
import Procedures from './containers/Documents/Procedures';
import AdministrationCouncil from './containers/Lists/AdministrationCouncil';
import ParentsCouncil from './containers/Lists/ParentsCouncil';
import StudentsCouncil from './containers/Lists/StudentsCouncil';
import Management from './containers/Lists/Management';
import QualityComission from './containers/Lists/QualityComission';
import Personal from './containers/Lists/Personal';
import Chairs from './containers/Lists/Chairs';
import FinancialDeclarations from './containers/Lists/FinancialDeclarations';
import AdminManagerialDocuments from './containers/Admin/AdminManagerialDocuments/AdminManagerialDocuments';
import AdminProcedures from './containers/Admin/AdminProcedures/AdminProcedures';
import AdminAdministrationCouncil from './containers/Admin/AdminAdministrationCouncil/AdminAdministrationCouncil';
import AdminParentsCouncil from './containers/Admin/AdminParentsCouncil/AdminParentsCouncil';
import AdminStudentsCouncil from './containers/Admin/AdminStudentsCouncil/AdminStudentsCouncil';
import AdminQualityComission from './containers/Admin/AdminQualityComission/AdminQualityComission';
import UserAnnouncements from './containers/Announcements/UserAnnouncements';
import AdminLeadPeople from './containers/Admin/AdminLeadPeople/AdminLeadPeople';
import WealthDeclaration from './containers/Admin/WealthDeclarations/WealthDeclarations';
import UserWealthDeclarations from './containers/Lists/UserWealthDeclarations';
import AdminOffer from './containers/Admin/AdminOffer/AdminOffer';
import EducationalOffer from './containers/EducationalOffer/EducationalOffer';
import { loginService } from '../src/services/LoginService';

class App extends React.Component {
  render(){
    return (
      <div>
        <Router>
          <div>
              <DefaultRoute private exact path="/admin/login" component={LogIn}/>
              <PrivateAdminRoute private exact path="/admin/hotarari-consiliu-administratie" component={CouncilDecisions}/>
              <DefaultRoute exact path="/hotarari-ca" component={DecisionsCA}/>

              <DefaultRoute exact path="/" component={Homepage}/>
              <DefaultRoute exact path="/despre-noi" component={About}/>
              <DefaultRoute exact path="/organigrama" component={Organigram}/>
              <DefaultRoute exact path="/valori" component={Values}/>
              <DefaultRoute exact path="/rezultate" component={Results}/>
              <DefaultRoute exact path="/proiecte" component={Projects}/>
              <DefaultRoute exact path="/admitere" component={Admission}/>
              <DefaultRoute exact path="/noutati" component={News}/>
              <DefaultRoute exact path="/anunt/:id" component={Announcement}/>
              <DefaultRoute exact path="/contact" component={Contact}/>
              <PrivateAdminRoute private exact path="/admin/buget" component={Budget}/>
              <PrivateAdminRoute private exact path="/admin/linkuri-utile" component={UsefulLinks}/>
              <PrivateAdminRoute private exact path="/admin/evaluare-nationala" component={NationalEvaluation}/>
              <PrivateAdminRoute private exact path="/admin/reviste-scolare" component={Magazines}/>
              <PrivateAdminRoute private exact path="/admin/regulamente" component={Regulaments}/>
              <PrivateAdminRoute private exact path="/admin/proiecte" component={AdminProjects}/>
              <PrivateAdminRoute private exact path="/admin/anunturi" component={Announcements}/>
              <PrivateAdminRoute private exact path="/admin/posturi-vacante" component={VacantPosts}/>
              <PrivateAdminRoute private exact path="/admin/rezultate" component={AdminResults}/>
              <PrivateAdminRoute private exact path="/admin/admitere" component={AdminAdmission}/>
              <DefaultRoute exact path="/documente-manageriale" component={ManagerialDocuments}/>
              <DefaultRoute exact path="/date-financiare" component={FinancialData}/>
              <DefaultRoute exact path="/regulamente" component={ViewRegulaments}/>
              <DefaultRoute exact path="/proceduri" component={Procedures}/>
              <DefaultRoute exact path="/consiliu-de-administratie" component={AdministrationCouncil}/>
              <DefaultRoute exact path="/consiliu-parinti" component={ParentsCouncil}/>
              <DefaultRoute exact path="/consiliu-elevi" component={StudentsCouncil}/>
              <DefaultRoute exact path="/conducere" component={Management}/>
              <DefaultRoute exact path="/comisie-evaluare-calitate" component={QualityComission}/>
              <DefaultRoute exact path="/personal" component={Personal}/>
              <DefaultRoute exact path="/catedre" component={Chairs}/>
              <DefaultRoute exact path="/oferta-educationala" component={EducationalOffer}/>
              <PrivateAdminRoute private exact path="/admin/documente-manageriale" component={AdminManagerialDocuments}/>
              <PrivateAdminRoute private exact path="/admin/proceduri" component={AdminProcedures}/>
              <PrivateAdminRoute private exact path="/admin/consiliu-de-administratie" component={AdminAdministrationCouncil}/>
              <PrivateAdminRoute private exact path="/admin/consiliu-parinti" component={AdminParentsCouncil}/>
              <PrivateAdminRoute private exact path="/admin/consiliu-elevi" component={AdminStudentsCouncil}/>
              <PrivateAdminRoute private exact path="/admin/comisie-evaluare-calitate" component={AdminQualityComission}/>
              <DefaultRoute exact path="/anunturi" component={UserAnnouncements}/>
              <PrivateAdminRoute private exact path="/admin/conducere" component={AdminLeadPeople}/>
              <PrivateAdminRoute private exact path="/admin/declaratii-avere" component={WealthDeclaration}/>
              <DefaultRoute private exact path="/declaratii-avere" component={UserWealthDeclarations}/>
              <PrivateAdminRoute private exact path="/admin/oferta-educationala" component={AdminOffer}/>
              {/*<Route private exact path="/admin/orar-note" component={ScheduleAndGrades}/>
              <Route private exact path="/admin/orar-note-mesaje" component={ProfessorInfo}/>
               <Route private exact path="/admin/note-clasa" component={SpecificClass}/>*/}
          </div>
        </Router>
      </div>
    );
  }

}

const DefaultRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
        <Component {...matchProps} />
    )}
  />
);

const PrivateAdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      loginService.loggedIn() ? (
          <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/admin/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
export default App;
