import React from 'react';
import './ScheduleAndGrades.scss';
import '../../../resources/StandardStyles.scss';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Schedule from '../../../components/Schedule/Schedule.jsx';
import Grades from '../../../components/Grades/Grades.jsx';

class ScheduleAndGrades extends React.Component{
    constructor(props) {
        super(props);
    
        this.state = {
          tabValue: 0,
          currentUser: {}
        };
      }
      handleChange = (event, tabValue) => {
        this.setState({
          tabValue
        });
      };
    
      handleChangeIndex = index => {
        this.setState({
          tabValue: index
        });
      };
    render(){
        return(
            <div className='standard sg'>
                <div className='sg__header-container'>
                    <div className='header__logo-box'>
                        <img className='header__logo' src='/images/presentation/sigla.png' alt='sigla scolii'/>
                        <div className='header__name-box'>
                            <h4 className='header__general-name'> COLEGIUL NATIONAL</h4>
                            <h3 className='header__specific-name sg__specific-name'> Mihai Eminescu Iasi</h3>
                        </div>
                    </div>   
                    <div className='sg__mobile-info'>
                        <div className='sg__name-round'>
                            <div className='sg__name-mobile-box'>
                                <h4 className='sg__name-mobile'>M</h4>
                                <h4 className='sg__name-mobile'>I</h4>
                            </div>
                        </div>
                        <img className='sg__icon' src='/images/icons/down-arrow.svg' alt='down-arrow'/>
                    </div>
                    <div className='sg__name-normal'>
                        <div className='sg__desktop-info'>
                            <h4 className='sg__name-desktop'>Mihai Irimescu</h4>
                            <h4 className='sg__class'>CLASA aXIIa D</h4>
                        </div>
                        <img className='sg__icon' src='/images/icons/down-arrow.svg' alt='down-arrow'/>
                    </div>
                </div>       
                <div className='sg__title-section'>
                <Tabs
                    value={this.state.tabValue}
                    onChange={this.handleChange}
                    name="tabValue"
                    indicatorColor="primary"
                    textColor="#001f70"
                    centered
                >
                    {}
                    <Tab className='standard__section-title' label="Orar" />
                    <Tab className='standard__section-title' label="Note" />
                </Tabs>

                    <SwipeableViews
                        axis="x"
                        index={this.state.tabValue}
                        onChangeIndex={this.handleChangeIndex}
                        >
                        {}
                        <div className="sg__schedule">
                                <Schedule/>
                        </div>
                        <div className="">
                            <Grades/>
                        </div>
                    </SwipeableViews>
                </div>        
            </div>
        )
    }
}

export default ScheduleAndGrades;