import React from 'react';
import './Announcement.scss';
import '../../resources/StandardStyles.scss';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header';
import {announcementService} from '../../services/AnnouncementsService';

class Announcement extends React.Component{
    constructor(props){
        super(props)
        this.state={
            announcement: {}
        }
    }

    // componentDidMount() {
    //     if (this.props.match.params.id) {
    //         announcementService.getAnnouncementBySlug(this.props.match.params.id).then(response=>{
    //           let announcement = { ...this.state.announcement };
    //           announcement = response.data;
    //           this.setState({ announcement });
    //       })
    //       .catch(err=>console.log(err))
    //     } else {
    //       this.props.history.push('/blog');
    //     }
    //   }

    render(){
        return(
            <div className='standard'>
             <Header/>
                <div className='standard__first-section'>
                    <h2 className='standard__general-title'>Olimpiada Interdisciplinara Stiintele Pamantului - etapa judeteana</h2>
                    <h4 className='announcement__date'>27 FEB 2020 · EVENIMENTE</h4>
                </div>
                <div className='announcement__container'>
                    <h3 className='standard__section-title'>Suspendare proces de învăţământ</h3>
                    <h4 className='standard__general-description announcement__info'>In perioada din cauza CODVID-19 toate cursurile sunt anulate. Mai multe detalii in poza de mai jos.</h4>
                    <h4 className='standard__general-description announcement__info'>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment.</h4>
                    <h4 className='standard__general-description announcement__info'>Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation X is on the runway heading towards a streamlined cloud solution. User generated content in real-time will have multiple touchpoints for offshoring.</h4>
                    <img className='announcement__image' src='/images/presentation/graduation.jfif' alt='announcement'/>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Announcement;