import React from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';
import '../../resources/StandardStyles.scss';
import footerJson from './footer.json';


class Footer extends React.Component{
    constructor(props){
        super(props)
        this.state={
            footer:footerJson
        }
    }
    scrollToTop=()=>{
        window.scrollTo(0, 0)
    }
    render(){
        return(
            <div className='standard footer'>
                                    <div className='footer__logo-box'>
                            <img className='footer__logo' src='/images/presentation/sigla.png' alt='sigla scolii'/>
                            <div className='footer__name-box'>
                                <h4 className='footer__general-name'> Școala Gimnazială</h4>
                                <h3 className='footer__specific-name'> ”Dimitrie Vatamaniuc” Sucevița</h3>
                            </div>
                        </div>
                <div className='footer__wrapper'>
                    <div className='footer__first-section'>
                        <div className='footer__contact'>
                            <h3 className='footer__info'>secretariat@scoalasucevita.ro</h3>
                            <h3 className='footer__info'>Strada Calea Movileştilor, nr. 151, Sucevita, jud. Suceava</h3>
                            <h3 className='footer__info'>0230 417 155</h3>
                        </div>    
                    </div>
                    <div className='footer__separator'/>
                    <ul className='footer__links'>
                    {this.state.footer.map(item=>(
                        <div key={item.id} className='footer__item-box'>
                            <Link onClick={this.scrollToTop} to={item.url} className='footer__link'><li className='footer__item'>{item.name}</li></Link>
                        </div>
                    ))}
                    </ul>
                 </div>
                 <div className=' footer__desktop-separator'/>
                 <div className='footer__contact-social'>
                    <div className='footer__contact footer__contact-desktop'>
                        <h3 className='footer__info footer__desktop-info'>Copyright © 2024 Scoala Gimnaziala</h3>
                        <h3 className='footer__info footer__desktop-info'>”Dimitrie Vatamaniuc” Sucevița</h3>
                        <h3 className='footer__info footer__desktop-info'>All rights reserved.</h3>
                    </div>
                    <div className='footer__social-box'>
                    <a href='https://www.facebook.com/scoala.sucevita'target='_blank' className='footer__link'><img className='footer__social-icon' src='/images/icons/facebook.svg' alt='facebook'/></a>
                    {/* <Link onClick={this.scrollToTop} to='/' className='footer__link'><img className='footer__social-icon' src='/images/icons/youtube.svg' alt='youtube'/></Link> */}
                    </div>
                </div>  
            </div>
        )
    }
}

export default Footer;