import React from 'react';
import './Contact.scss';
import '../../resources/StandardStyles.scss';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import ContactMap from '../../components/ContactMap/ContactMap';
import { contactService } from '../../services/ContactService';
import { loginService } from '../../services/LoginService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contact: {
                name: "",
                email: "",
                message: ""
            }
        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
    }
    handleChange = event => {
        const field = event.target.name;
        const contact = { ...this.state.contact };
        contact[field] = event.target.value;
        this.setState({
            contact
        });
    };

    handleSubmit = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;
        console.log('fddf')
        contactService.sendContactMessage(this.state.contact).then(response => {
            notification.addNotification({
                message: 'Mesajul a fost trimis cu succes.',
                level: 'success',
                position: 'tc'
            });
        })
            .catch(err => {
                notification.addNotification({
                    message:
                        'Mesajul nu a putut fi trimis. Mai incercati o data.',
                    level: 'error',
                    position: 'bc'
                });
            })
    }
    render() {
        return (
            <div className='standard contact'>
                <div className='standard admission'>
                    <Header />
                    <div className='standard__first-section'>
                        <h2 className='standard__general-title'>Contact</h2>
                    </div>
                </div>
                <div className='contact__container'>
                    <div className='contact__info-box'>
                        <h3 className='standard__section-title'>Informatii contact</h3>
                        <ul className="contact__info-list">
                            <div className='contact__details-box'>
                                <FontAwesomeIcon icon={faPhone} className='contact__icon' />
                                <li className='contact__detail'>Număr telefon: 0230 417 155</li>
                            </div>
                            <div className='contact__details-box'>
                                <FontAwesomeIcon icon={faEnvelope} className='contact__icon' />
                                <li className='contact__detail'> Email:secretariat@scoalasucevita.ro</li>
                            </div>
                            <div className='contact__details-box'>
                                <FontAwesomeIcon icon={faMapMarker} className='contact__icon' />
                                <li className='contact__detail'>Adresa: Strada Calea Movileştilor, nr. 151, comuna
                                    Suceviţa, județul Suceava, cod poştal: 727510</li>
                            </div>
                        </ul>
                    </div>
                    <div className='contact__form-box'>
                        <h3 className='standard__section-title'>Trimite-ne un mesaj</h3>
                        <form className='contact__form' onSubmit={this.handleSubmit}>
                            <input
                                className='contact__input'
                                type="text"
                                name="name"
                                onChange={this.handleChange}
                                required
                                value={this.state.contact.name}
                                placeholder="Nume" />


                            <input
                                className='contact__input'
                                type="text"
                                name="email"
                                onChange={this.handleChange}
                                required
                                value={this.state.contact.email}
                                onChange={this.handleChange}
                                placeholder="Email" />


                            <textarea cols="30"
                                rows="2"
                                required
                                type='text'
                                className='contact__text-area contact__input'
                                placeholder='Mesajul tău'
                                onChange={this.handleChange}
                                name="message"
                                value={this.state.contact.message}
                                onChange={this.handleChange}
                            />
                            <button type='submit' className='contact__submit-button'><h5 className='contact__button-writing'>TRIMITE EMAIL</h5></button>
                        </form>
                    </div>
                    <div className="contact__map-item">
                        <ContactMap />
                    </div>
                </div>
                <Footer />
                <NotificationSystem
                    ref={this.notificationSystem}
                    style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default Contact;