import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const announcementService = {
  getAll,
  createOrUpdate,
  deleteAnnouncement,
  getAnnouncementById
};
const requestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL}/api/announcements/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions)
    .then(responseHandleService.handleResponse)
    .catch(error => console.log(error));
}
function createOrUpdate(newAnnouncement) {
  const requestOptions = {
    method: newAnnouncement.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newAnnouncement)
  };

  let authentication = new AuthenticationService();

  console.log(authentication.loggedIn());

  if (authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/announcements${
    newAnnouncement.id ? `/${newAnnouncement.id}` : ''
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}
function createBodyRequest(announcement) {
  let formData = new FormData();

  formData.append('annoucement[title]', announcement.title);
  formData.append('annoucement[date]', announcement.date);
  formData.append('annoucement[description]', announcement.description);
  const document = announcement.document;

  if (document) {
    if (document.url) {
      formData.append(`annoucement[document]`, document.url);
    } else {
      formData.append(`annoucement[document]`, document, document.name);
    }
  }

  return formData;
}
function deleteAnnouncement(announcementId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/announcements/${announcementId}`;

  return axios.delete(url, requestOptionsDelete);
}
function getAnnouncementById(id) {
  const requestOptionsPost = {
      headers: {
        Accept: 'application/json'
      }
    };

    let url = `${process.env.REACT_APP_BLOG_API_URL}/api/announcements/get_by_id/${id}`;


   return axios.get(url, requestOptionsPost);
}
