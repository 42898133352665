import React from 'react';
import AdminMenu from '../../../components/AdminMenu/AdminMenu';
import AttachementField from '../../../components/AttachementField/AttachementField';
import '../../../resources/adminStyle.scss';
import {decisionService} from '../../../services/CouncilDecisionsService';
import NotificationSystem from 'react-notification-system';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AuthenticationService from '../../../services/AuthenticationService';

class CouncilDecisions extends React.Component{
    constructor(props){
        super(props)
        this.state={
           newDecision:{
            title:'',
            date:'',
            number: '',
            document:''
           },
           existingDecisions:[],
           deleteDecision: {
            openedDialog: false,
            deleteId: 0
          },
          pagination: {}
        }
        this.authentication = new AuthenticationService();
    }
    handleChange=event=>{
        let newDecision = { ...this.state.newDecision };
        newDecision[event.target.name] = event.target.value;

          this.setState({ newDecision });
      }


      componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        decisionService
        .getAll()
        .then(response => {
          this.setDecisionState(response);
        })
        .catch(error => {
          console.log(error);
        });
      }
      setDecisionState = response => {
        let existingDecisions = this.state.existingDecisions;
        existingDecisions = response.council_decisions;
        this.setState({
            existingDecisions
        });
        let pagination = { ...this.state.pagination };
        pagination = response.pagination;
        this.setState({
          pagination
        });
      };
      handlePageClick = currentPage => {
        decisionService
          .getAll(currentPage.selected + 1)
          .then(response => {
            this.setDecisionState(response);
          })
          .catch(error => console.log(error));
      };
      resetForm = () => {
        let newDecision = { ...this.state.newDecision };
        newDecision = {
          title: '',
          date:'',
          number: '',
          description:'',
          document: ''
        };
        this.setState({ newDecision });
        this.setState({ disabledDocument: false });
      };
      openDeleteDialog = id => {
        let deleteDecision = { ...this.state.deleteDecision };
        deleteDecision = {
          openedDialog: true,
          deleteId: id
        };

        this.setState({ deleteDecision });
      };

      closeDeleteDialog = () => {
        let deleteDecision = { ...this.state.deleteDecision };
        deleteDecision = {
          openedDialog: false,
          deleteId: 0
        };

        this.setState({ deleteDecision });
      };
      handleFormSubmit = event => {
        event.preventDefault();

        decisionService
          .createOrUpdate(this.state.newDecision)
          .then(response => {

            let existingDecisions = this.state.existingDecisions;

            let index = existingDecisions.findIndex(
              existingDecisions => existingDecisions.id === response.id
            );

            if (index !== -1) {
              existingDecisions[index] = response;
            } else {
              existingDecisions.push(response);
            }

            this.setState({
              existingDecisions
            });

            this._notificationSystem.addNotification({
              message: `Decizia consiliului a fost ${
                this.state.newDecision.id ? 'editata' : 'adaugata'
              } cu success.`,
              level: 'success',
              position: 'tc'
            });

            this.resetForm();
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problemă la adăugare, faceti refresh la pagina sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
      onDropDocument = documents => {
        let newDecision = { ...this.state.newDecision };
        let reader = new FileReader();
        let self = this;

        reader.onload = function(upload) {
          newDecision.document = documents[0];

          self.setState({ newDecision });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };

      removeDocument = () => {
        let newDecision = { ...this.state.newDecision };
        newDecision.document = null;

        this.setState({ newDecision });
        this.setState({ disabledDocument: false });
      };

      setDecisionForEdit = (event, decision) => {
          event.preventDefault();

        let newDecision = { ...this.state.newDecision };
        newDecision = Object.assign({}, decision);
        this.setState({ newDecision });

        if (newDecision.document && newDecision.document.url) {
          this.setState({ disabledDocument: true });
        }
      };

      handleDelete = () => {
        decisionService
          .deleteDecision(this.state.deleteDecision.deleteId)
          .then(() => {
            let existingDecisions = this.state.existingDecisions.slice();
            existingDecisions = existingDecisions.filter(
                decision => decision.id !== this.state.deleteDecision.deleteId
            );
            this.setState({ existingDecisions });

            this.closeDeleteDialog();

            this._notificationSystem.addNotification({
              message: 'Decizia consiliului a fost stearsa cu success.',
              level: 'success',
              position: 'tc'
            });
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problema la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
    render(){
        return(
            <div className='admin'>
                <AdminMenu/>
                <div className='admin__wrapper'>
                    <h2 className='admin__title'>Hotărâri ale Consiliului de Administrație</h2>
                    <h3 className='admin__subtitle'>Adaugă o decizie:</h3>
                        <form className='admin__form'onSubmit={this.handleFormSubmit}>
                            <h4 className='admin__label'>Titlu document:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="title"
                                onChange={this.handleChange}
                                required
                                value={this.state.newDecision.title}/>
                            <h4 className='admin__label'>Data:</h4>
                            <input
                                className="admin__field"
                                type="date"
                                name="date"
                                required
                                onChange={this.handleChange}
                                value={this.state.newDecision.date}/>
                            <h4 className='admin__label'>Numar Decizie:</h4>
                            <input
                                className="admin__field"
                                type="number"
                                name="number"
                                required
                                onChange={this.handleChange}
                                value={this.state.newDecision.number}/>
                            <div className='admin__attachement'>
                                <AttachementField
                                onDrop={this.onDropDocument}
                                removeDocument={this.removeDocument}
                                document={this.state.newDecision.document}
                                documentFilename={
                                    this.state.newDecision.document_filename
                                }
                                type="application/pdf, image/*"
                                />
                            </div>
                            <button type='submit' className='admin__submit-button'> {this.state.newDecision.id
                        ? 'Editează'
                        : 'Adaugă'} decizia</button>
                        </form>
                        <div className='admin__existing-documents'>
                        {this.state.existingDecisions && this.state.existingDecisions.length?  <h3 className='admin__no-documents'> Documente existente:</h3> : ''}
                        {this.state.existingDecisions && this.state.existingDecisions.length?
                        <div className='admin__existing-docs-wrapper'>
                         {this.state.existingDecisions.map(decision=>
                            ( <div className='admin__card' key={decision.id}>
                                 <h3 className='admin__card-title'>{decision.title}</h3>
                                 <div className='admin__date-box'>
                                    <img src='/images/icons/calendar.png' alt='calendar'/>
                                    <h4 className='admin__card-date'>{decision.date}</h4>
                                 </div>
                                 <div className='admin__document-box'  onClick={() =>
                                    window.open(
                                        `${process.env.REACT_APP_API_URL}${
                                        decision.document.url
                                        }`
                                    )
                                    }>
                                     <img src='/images/icons/file.png' alt='file'/>
                                     <h3 className='admin__document-name'>Vezi document</h3>
                                 </div>
                                 <div className='admin__actions'>
                                    <h4 className='admin__edit' onClick={event => this.setDecisionForEdit(event, decision)} >Editează</h4>
                                    <h4 className='admin__delete'  onClick={() => this.openDeleteDialog(decision.id)}>Șterge</h4>
                                 </div>
                             </div>)
                         )}
                         </div>:
                        <h3 className='admin__no-documents'> Nu sunt documente adăugate.</h3>
                    }


                        </div>
                </div>
                <NotificationSystem
                    ref="notificationSystem"
                    style={NotificationSystemStyle}
                    />
                 <DeletePUGCertificates
                    opened={this.state.deleteDecision.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur că doriti să ștergeti aceasta decizie?"
                    aditionalText="Dupa ștergere, decizia nu va mai putea fi recuperata."
                  />
            </div>
        )
    }
}
export default CouncilDecisions;
