
import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const offerService = {
  getAll,
  createOrUpdate,
  deleteoffer
};
const requestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL}/api/educational_offers/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions)
    .then(responseHandleService.handleResponse)
    .catch(error => console.log(error));
}
function createOrUpdate(newOffer) {
  const requestOptions = {
    method: newOffer.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newOffer)
  };

  let authentication = new AuthenticationService();

  console.log(authentication.loggedIn());

  if (authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/educational_offers${
    newOffer.id ? `/${newOffer.id}` : ''
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}
function createBodyRequest(offer) {
  let formData = new FormData();

  formData.append('educational_offer[name]', offer.name);
  formData.append('educational_offer[staff]', offer.staff);
  formData.append('educational_offer[classroom]', offer.classroom);
  formData.append('educational_offer[discipline]', offer.discipline);
  formData.append('educational_offer[optional_type]', offer.optionalType);
  formData.append('educational_offer[year]', offer.year);


  return formData;
}
function deleteoffer(offerId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/educational_offers/${offerId}`;

  return axios.delete(url, requestOptionsDelete);
}
