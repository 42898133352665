import React from 'react';
import './Projects.scss';
import '../../resources/StandardStyles.scss';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header';
import {projectService} from '../../services/ProjectService';

class Projects extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          documents: [],
          pagination: ''
        };
      }
      componentWillMount() {
        projectService
          .getAll()
          .then(jsonResponse => {
            console.log(jsonResponse);
            this.setProjects(jsonResponse);
          })
          .catch(error => console.log(error));
      }
      setProjects = response => {
        let documents = { ...this.state.documents };
        let pagination = { ...this.state.pagination };
        documents = response.projects;
        pagination = response.pagination;
    
        this.setState({ documents });
        this.setState({ pagination });
    
        console.log(this.state.documents);
      };
    render(){
        return(
            <div className='standard projects'>
                <Header/>
                <div className='standard__first-section'>
                    <h2 className='standard__general-title'>Proiecte ale școlii</h2>
                </div>
                <div className='projects__container'>
                {this.state.documents.map(project=>(
                    <div className='projects__individual' key={project.id}>
                    <img className='projects__image'  src={project.document ? process.env.REACT_APP_API_URL + project.document.url : '/images/presentation/project.png'} alt='project-image'/>
                    <h3 className='projects__title'>{project.title}</h3>
                    <h5 className='projects__date'>{project.date}</h5>
                    <h4 className='projects__description'>{project.description}</h4>
                    <h5 className='projects__see-more' onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_API_URL}${
                                project.document.url
                              }`
                            )
                          }>Citește mai mult</h5>
                </div>
                ))}
                    
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Projects;