import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const magazineService = {
  getAll,
  createOrUpdate,
  deleteMagazine
};
const requestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL}/api/magazines/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions)
    .then(responseHandleService.handleResponse)
    .catch(error => console.log(error));
}
function createOrUpdate(newMagazine) {
  const requestOptions = {
    method: newMagazine.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newMagazine)
  };

  let authentication = new AuthenticationService();

  console.log(authentication.loggedIn());

  if (authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/magazines${
    newMagazine.id ? `/${newMagazine.id}` : ''
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}
function createBodyRequest(magazine) {
  let formData = new FormData();

  formData.append('magazine[title]', magazine.title);
  formData.append('magazine[date]', magazine.date);
  const document = magazine.document;

  if (document) {
    if (document.url) {
      formData.append(`magazine[document]`, document.url);
    } else {
      formData.append(`magazine[document]`, document, document.name);
    }
  }

  return formData;
}
function deleteMagazine(magazineId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/magazines/${magazineId}`;

  return axios.delete(url, requestOptionsDelete);
}
