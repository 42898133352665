import React from 'react';
import '../../resources/StandardStyles.scss';
import './Grades.scss';
import gradesJson from './gradesJson.json';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

class Grades extends React.Component{

    constructor(props){
        super(props);

        this.state={
            grades:gradesJson,
            classType:"",
            name:""
        }
    }
    handleChange=event=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    }
    toggleGradesBox = currentGrade => {
        let grades = this.state.grades;
    
        grades.map(grade => {
          if (grade.id === currentGrade.id) {
            grade.openedBox = !grade.openedBox;
          }
        });
    
        this.setState({
            grades
        });
      };
    
    render(){
        return(
            <div className='grades__container'>
                        <form className='grades__form'>
                            <div className='grades__search-box'>
                                <input 
                                className='grades__input'
                                name="classType"
                                onChange={this.handleChange}
                                required
                                value={this.state.classType}
                                onChange={this.handleChange}
                                placeholder="Cauta materie"/>
                                <img className='grades__search-icon' src='/images/icons/search.svg' alt='search-icon'/>
                            </div>
                            <FormControl className="solicitation-modal__status">
                                <NativeSelect>
                                    <option value="name">Sorteaza dupa</option>
                                    <option>1</option>
                                    <option>2</option>
                                </NativeSelect>
                            </FormControl>
                        </form>
                <div className='grades__wrapper'>
                {
                this.state.grades.map(item=>(
                    <div key={item.id} className='standard__schedule-box'>
                        <div className='grades__subject-box'>
                            <h3 className='standard__schedule-title grades__subject'>{item.name}</h3>
                            <img className='grades__arrow-icon' src={ item.openedBox ? '/images/icons/up-arrow.svg':'/images/icons/down-arrow.svg'} alt='arrow'
                            onClick={event =>this.toggleGradesBox(item)} />
                        </div>
                        
                            <div className={'grades__grades-box '+(item.openedBox? '':'grades__closed-box')}>
                                {item.grades.map(classItem=>(
                                    <div key={classItem.id} className='standard__schedule-info grades__info'>
                                        <h4 className='standard__schedule-hour grades__date'>{classItem.date}</h4>
                                        <h4 className='standard__schedule-class'>{classItem.grade}</h4>
                                    </div>
                                ))}
                                <div className='grades__media-box'>
                                    <h4 className='standard__schedule-class'>MEDIA</h4>
                                    <h4 className='grades__adding-grades'>{item.addingGrades}</h4>
                                </div>
                            </div>
                    </div>
                ))
                    }
                    <div className='grades__general-media'>
                        <h4 className='standard__schedule-class'>MEDIA GENERALA</h4>
                        <h4 className='grades__adding-grades'>9.2</h4>
                    </div>
                </div>
            </div>
        )
    }
}

export default Grades;