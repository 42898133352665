import React from 'react';
import './HamburgerMenu.scss';
import { Link } from 'react-router-dom';
import menuJson from '../../Menu/menu.json';

class HamburgerMenu extends React.Component{
    constructor(props){
        super(props)
        this.state={
            activeMenu:false,
            menu:menuJson
        }
    }
    openMenu=() =>{
        this.setState({
            activeMenu:true       })
            document.body.classList.add('has-active-menu');
    };
    closeMenu=()=>{
        this.setState({
            activeMenu:false        })
            document.body.classList.remove('has-active-menu');
    }
    toggleSubmenu=(subMenuId)=>{
    let menu = this.state.menu;

    menu.forEach(menuItem => {
      if (menuItem.id === subMenuId) {
        menuItem.open = !menuItem.open;
      }
    });
    this.setState({ menu });
  }
  
    render(){
        return(
            <div className="standard hamburger">
              <img
                onClick={this.openMenu}
                className="hamburger__icon"
                src='/images/icons/menu.png'
                alt="hamburgetMenuLogo"
              />
            <ul
              className={
                'hamburger__links' + (this.state.activeMenu ? ' active' : '')
              }
            >
              <div className='hamburger__intro'>
                <img src='/images/presentation/sigla.png' alt='logo'/>
                <div>
                  <h5>Școala Gimnazială</h5>
                  <h4>Dimitrie Vatamaniuc Sucevița</h4>
                </div>
              </div>
              {this.state.menu.map(listItem => (
               <div key={listItem.id} className='hamburger__list-box'>
                    <div className={listItem.submenu? 'hamburger__link-box':''}>
                    <Link className='hamburger__link' to={listItem.url}><li className='hamburger__list-item'>{listItem.name}</li></Link>
                    {listItem.submenu? <img src={listItem.open? '/images/icons/arrow-up.png':'/images/icons/arrow-down.png'} alt='arrow' className='hamburger__arrow' onClick={()=>this.toggleSubmenu(listItem.id)}/>: ''}
                    </div>
                   {listItem.open? <div >
                     {listItem.submenu.map(submenuItem=>(
                       <div key={submenuItem.id} >
                          <Link className='hamburger__link' to={submenuItem.url}><li className='hamburger__submenu-list-item'>{submenuItem.name}</li></Link>
                        </div>
                     ))}
                    </div>
                    :''} 
                </div>
              ))}
              <Link className='hamburger__link' to='/noutati'><li className='hamburger__list-item'>Noutăți</li></Link>
              <Link className='hamburger__link' to='/contact'><li className='hamburger__list-item'>Contact</li></Link>
              <Link className='hamburger__link' to='/admin/login'><button className='hamburger__platform-access'><h5 className='hamburger__platform'>Acces Platformă</h5></button></Link>
            </ul>
              <img
                onClick={this.closeMenu}
                className={
                  'hamburger__close-icon' + (this.state.activeMenu ? ' active' : '')
                }
                src="/images/icons/close-icon.png"
                alt="close Icon"
              />
          </div>
        )
    }
}

export default HamburgerMenu;