import React from 'react';
import AdminMenu from '../../../components/AdminMenu/AdminMenu';
import AttachementField from '../../../components/AttachementField/AttachementField';
import '../../../resources/adminStyle.scss';
import {procedureService} from '../../../services/ProceduresService';
import NotificationSystem from 'react-notification-system';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AuthenticationService from '../../../services/AuthenticationService';

class AdminProcedures extends React.Component{
    constructor(props){
        super(props)
        this.state={
           newProcedure:{
            title:'',
            date:'',
            document:''
           },
           existingProcedures:[],
           deleteProcedure: {
            openedDialog: false,
            deleteId: 0
          },
          pagination: {}
        }
        this.authentication = new AuthenticationService();
    }
    handleChange=event=>{
        let newProcedure = { ...this.state.newProcedure };
        newProcedure[event.target.name] = event.target.value;
  
          this.setState({ newProcedure });
      }

      componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        procedureService
        .getAll()
        .then(response => {
          this.setProcedureState(response);
        })
        .catch(error => {
          console.log(error);
        });
      }
      setProcedureState = response => {
        let existingProcedures = this.state.existingProcedures;
        existingProcedures = response.procedures;
        this.setState({
            existingProcedures
        });
        let pagination = { ...this.state.pagination };
        pagination = response.pagination;
        this.setState({
          pagination
        });
      };
      handlePageClick = currentPage => {
        procedureService
          .getAll(currentPage.selected + 1)
          .then(response => {
            this.setProcedureState(response);
          })
          .catch(error => console.log(error));
      };
      resetForm = () => {
        let newProcedure = { ...this.state.newProcedure };
        newProcedure = {
          title: '',
          date:'',
          description:'',
          document: ''
        };
        this.setState({ newProcedure });
        this.setState({ disabledDocument: false });
      };
      openDeleteDialog = id => {
        let deleteProcedure = { ...this.state.deleteProcedure };
        deleteProcedure = {
          openedDialog: true,
          deleteId: id
        };
    
        this.setState({ deleteProcedure });
      };
    
      closeDeleteDialog = () => {
        let deleteProcedure = { ...this.state.deleteProcedure };
        deleteProcedure = {
          openedDialog: false,
          deleteId: 0
        };
    
        this.setState({ deleteProcedure });
      };
      handleFormSubmit = event => {
        event.preventDefault();
    
        procedureService
          .createOrUpdate(this.state.newProcedure)
          .then(response => {
    
            let existingProcedures = this.state.existingProcedures;
    
            let index = existingProcedures.findIndex(
              existingProcedures => existingProcedures.id === response.id
            );
    
            if (index !== -1) {
              existingProcedures[index] = response;
            } else {
              existingProcedures.push(response);
            }
    
            this.setState({
              existingProcedures
            });
    
            this._notificationSystem.addNotification({
              message: `Procedura a fost ${
                this.state.newProcedure.id ? 'editata' : 'adaugata'
              } cu success.`,
              level: 'success',
              position: 'tc'
            });

            this.resetForm();
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problemă la adăugare, faceti refresh la pagina sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
      onDropDocument = documents => {
        let newProcedure = { ...this.state.newProcedure };
        let reader = new FileReader();
        let self = this;
    
        reader.onload = function(upload) {
          newProcedure.document = documents[0];
    
          self.setState({ newProcedure });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };
    
      removeDocument = () => {
        let newProcedure = { ...this.state.newProcedure };
        newProcedure.document = null;
    
        this.setState({ newProcedure });
        this.setState({ disabledDocument: false });
      };
    
      setProcedureForEdit = (event, procedure) => {
          event.preventDefault();

        let newProcedure = { ...this.state.newProcedure };
        newProcedure = Object.assign({}, procedure);
        this.setState({ newProcedure });
    
        if (newProcedure.document && newProcedure.document.url) {
          this.setState({ disabledDocument: true });
        }
      };
    
      handleDelete = () => {
        procedureService
          .deleteProcedure(this.state.deleteProcedure.deleteId)
          .then(() => {
            let existingProcedures = this.state.existingProcedures.slice();
            existingProcedures = existingProcedures.filter(
                procedure => procedure.id !== this.state.deleteProcedure.deleteId
            );
            this.setState({ existingProcedures });
    
            this.closeDeleteDialog();
    
            this._notificationSystem.addNotification({
              message: 'Procedura a fost ștearsa cu success.',
              level: 'success',
              position: 'tc'
            });
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problema la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
    render(){
        return(
            <div className='admin'>
                <AdminMenu/>
                <div className='admin__wrapper'>
                    <h2 className='admin__title'>Proceduri</h2>
                    <h3 className='admin__subtitle'>Adaugă o procedura:</h3>
                        <form className='admin__form' onSubmit={this.handleFormSubmit}>
                            <h4 className='admin__label'>Titlu document:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="title"
                                onChange={this.handleChange}
                                required
                                value={this.state.newProcedure.title}/>
                            <h4 className='admin__label'>Data:</h4>
                            <input
                                className="admin__field"
                                type="date"
                                name="date"
                                required
                                onChange={this.handleChange}
                                value={this.state.newProcedure.date}/>
                            <div className='admin__attachement'>
                                <AttachementField
                                onDrop={this.onDropDocument}
                                removeDocument={this.removeDocument}
                                document={this.state.newProcedure.document}
                                documentFilename={
                                    this.state.newProcedure.document_filename
                                }
                                type="image/*"
                                />
                            </div>
                            <button type='submit' className='admin__submit-button'> {this.state.newProcedure.id
                        ? 'Editează'
                        : 'Adaugă'} procedura</button>       
                        </form>
                        <div className='admin__existing-documents'>
                        {this.state.existingProcedures && this.state.existingProcedures.length?  <h3 className='admin__no-documents'> Documente existente:</h3> : ''} 
                        {this.state.existingProcedures && this.state.existingProcedures.length? 
                        <div className='admin__existing-docs-wrapper'>
                         {this.state.existingProcedures.map(procedure=>
                            ( <div className='admin__card'>
                                 <h3 className='admin__card-title'>{procedure.title}</h3>
                                 <div className='admin__date-box'>
                                    <img src='/images/icons/calendar.png' alt='calendar'/>
                                    <h4 className='admin__card-date'>{procedure.date}</h4>
                                 </div>
                                 <p className='admin__card-description'>{procedure.description}</p>
                                 <div className='admin__document-box'  onClick={() =>
                                    window.open(
                                        `${process.env.REACT_APP_API_URL}${
                                        procedure.document.url
                                        }`
                                    )
                                    }>
                                     <img src='/images/icons/file.png' alt='file'/>
                                     <h3 className='admin__document-name'>Vezi document</h3>
                                 </div>
                                 <div className='admin__actions'>
                                    <h4 className='admin__edit' onClick={event => this.setProcedureForEdit(event, procedure)} >Editează</h4>
                                    <h4 className='admin__delete'  onClick={() => this.openDeleteDialog(procedure.id)}>Șterge</h4>
                                 </div>
                             </div>)
                         )}
                         </div>: 
                        <h3 className='admin__no-documents'> Nu sunt documente adăugate.</h3>
                    }
                       
                               
                        </div>
                </div>
                <NotificationSystem
                    ref="notificationSystem"
                    style={NotificationSystemStyle}
                    />
                 <DeletePUGCertificates
                    opened={this.state.deleteProcedure.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur că doriti să ștergeti acest document?"
                    aditionalText="Dupa ștergere, documentul nu va mai putea fi recuperat."
                  />
            </div>
        )
    }
}
export default AdminProcedures;