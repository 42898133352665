import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const evaluationService = {
  getAll,
  createOrUpdate,
  deleteEvaluation
};
const requestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL}/api/national_evaluations/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions)
    .then(responseHandleService.handleResponse)
    .catch(error => console.log(error));
}
function createOrUpdate(newEvaluation) {
  const requestOptions = {
    method: newEvaluation.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newEvaluation)
  };

  let authentication = new AuthenticationService();

  console.log(authentication.loggedIn());

  if (authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/national_evaluations${
    newEvaluation.id ? `/${newEvaluation.id}` : ''
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}
function createBodyRequest(evaluation) {
  let formData = new FormData();

  formData.append('national_evaluation[title]', evaluation.title);
  formData.append('national_evaluation[date]', evaluation.date);
  const document = evaluation.document;

  if (document) {
    if (document.url) {
      formData.append(`national_evaluation[document]`, document.url);
    } else {
      formData.append(`national_evaluation[document]`, document, document.name);
    }
  }

  return formData;
}
function deleteEvaluation(evaluationId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/national_evaluations/${evaluationId}`;

  return axios.delete(url, requestOptionsDelete);
}
