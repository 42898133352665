import React from 'react';
import '../../resources/StandardStyles.scss';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header';
import {offerService} from '../../services/OfferService';


class EducationalOffer extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          offers: []
        };
      }
      componentDidMount(){
        offerService.getAll().then(response=>{
           let offers=response.educational_offers;
           console.log(response.data)
           this.setState({offers})
        })
        .catch(err=>console.log(err))
    }
    render(){
        return(
            <div className='standard admission'>
            <Header/>
                <div className='standard__first-section'>
                    <h2 className='standard__general-title'>Oferta educationala</h2>
                </div>
                <div className='admission__container'>
                    <h3 className='standard__section-title'>Oferte educaționale</h3>
                    <div className='admission__individual-type'>
                    {this.state.offers.map(offer=>(
                         <div className='admission__individual' key={offer.id}>
                         <h3 className='admission__number'>{offer.name}</h3>
                         <h4 className='admission__info'>Profesor: {offer.staff}</h4>
                         <p className='admission__description'>Disciplina: {offer.discipline}</p>
                         <p className='admission__description'>Tipul optionalului: {offer.optional_type}</p>
                         <p className='admission__description'>Clasa: {offer.classroom}</p>
                     </div>
                    ))}
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default EducationalOffer;