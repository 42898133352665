import React from 'react';
import './Header.scss';
import '../../resources/StandardStyles.scss';
import Menu from '../Menu/Menu.jsx';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import { Link } from 'react-router-dom';


class Header extends React.Component{
    render(){
        return(
            <div className='standard header'>
                <div className='header__mobile-menu'>
                    <Link to='/' className='standard__link'>
                        <div className='header__logo-box'>
                            <img className='header__logo' src='/images/presentation/sigla.png' alt='sigla scolii'/>
                            <div className='header__name-box'>
                                <h4 className='header__general-name'>Școala Gimnazială </h4>
                                <h3 className='header__specific-name'>  ”Dimitrie Vatamaniuc” Sucevița</h3>
                            </div>
                        </div>  
                    </Link>  
                <HamburgerMenu/>
                </div>
                <div className='header__menu-box'>
                    <ul className='header__supramenu'>
                       <Link className='standard__link' to='/noutati'> <li className='header__supramenu-item'>NOUTĂȚI</li></Link>
                       <Link className='standard__link' to='/contact'> <li className='header__supramenu-item'>CONTACT</li> </Link>
                        <Link to='/admin/login' className='standard__link'><li className='header__supramenu-item'>ACCES PLATFORMA</li></Link>
                    </ul>
                    <Menu/>
                </div>
            </div>
        )
    }
}

export default Header;