import React from 'react';
import AdminMenu from '../../../components/AdminMenu/AdminMenu';
import AttachementField from '../../../components/AttachementField/AttachementField';
import '../../../resources/adminStyle.scss';
import {offerService} from '../../../services/OfferService';
import NotificationSystem from 'react-notification-system';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AuthenticationService from '../../../services/AuthenticationService';

class AdminOffer extends React.Component{
    constructor(props){
        super(props)
        this.state={
           newOffer:{
            name:'',
            classroom:'',
            staff:'',
            discipline:"",
            optionalType:'',
            year:''
           },
           existingOffers:[],
           deleteoffer: {
            openedDialog: false,
            deleteId: 0
          },
          pagination: {}
        }
        this.authentication = new AuthenticationService();
    }
    handleChange=event=>{
        let newOffer = { ...this.state.newOffer };
        newOffer[event.target.name] = event.target.value;
  
          this.setState({ newOffer });
      }
      componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        offerService
        .getAll()
        .then(response => {
          this.setOfferState(response);
        })
        .catch(error => {
          console.log(error);
        });
      }
      setOfferState = response => {
        let existingOffers = this.state.existingOffers;
        existingOffers = response.educational_offers;
        this.setState({
            existingOffers
        });
        let pagination = { ...this.state.pagination };
        pagination = response.pagination;
        this.setState({
          pagination
        });
      };
      handlePageClick = currentPage => {
        offerService
          .getAll(currentPage.selected + 1)
          .then(response => {
            this.setOfferState(response);
          })
          .catch(error => console.log(error));
      };
      resetForm = () => {
        let newOffer = { ...this.state.newOffer };
        newOffer = {
            name:'',
            classroom:'',
            staff:'',
            discipline:"",
            optionalType:'',
            year:''
        };
        this.setState({ newOffer });
        this.setState({ disabledDocument: false });
      };
      openDeleteDialog = id => {
        let deleteoffer = { ...this.state.deleteoffer };
        deleteoffer = {
          openedDialog: true,
          deleteId: id
        };
    
        this.setState({ deleteoffer });
      };
    
      closeDeleteDialog = () => {
        let deleteoffer = { ...this.state.deleteoffer };
        deleteoffer = {
          openedDialog: false,
          deleteId: 0
        };
    
        this.setState({ deleteoffer });
      };
      handleFormSubmit = event => {
        event.preventDefault();
    
        offerService
          .createOrUpdate(this.state.newOffer)
          .then(response => {
    
            let existingOffers = this.state.existingOffers;
    
            let index = existingOffers.findIndex(
              existingOffers => existingOffers.id === response.id
            );
    
            if (index !== -1) {
              existingOffers[index] = response;
            } else {
              existingOffers.push(response);
            }
    
            this.setState({
              existingOffers
            });
    
            this._notificationSystem.addNotification({
              message: `Oferta a fost ${
                this.state.newOffer.id ? 'editata' : 'adaugata'
              } cu success.`,
              level: 'success',
              position: 'tc'
            });

            this.resetForm();
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problemă la adăugare, faceti refresh la pagina sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
      setOfferForEdit = (event, offer) => {
          event.preventDefault();

        let newOffer = { ...this.state.newOffer };
        newOffer = Object.assign({}, offer);
        this.setState({ newOffer });
    
        if (newOffer.document && newOffer.document.url) {
          this.setState({ disabledDocument: true });
        }
      };
    
      handleDelete = () => {
        offerService
          .deleteoffer(this.state.deleteoffer.deleteId)
          .then(() => {
            let existingOffers = this.state.existingOffers.slice();
            existingOffers = existingOffers.filter(
                offer => offer.id !== this.state.deleteoffer.deleteId
            );
            this.setState({ existingOffers });
    
            this.closeDeleteDialog();
    
            this._notificationSystem.addNotification({
              message: 'Oferta a fost stearsa cu success.',
              level: 'success',
              position: 'tc'
            });
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problema la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
    render(){
        return(
            <div className='admin'>
                <AdminMenu/>
                <div className='admin__wrapper'>
                    <h2 className='admin__title'>Oferte Educationale</h2>
                    <h3 className='admin__subtitle'>Adaugă o oferta educationala:</h3>
                        <form className='admin__form' onSubmit={this.handleFormSubmit}>
                            <h4 className='admin__label'>Titlu oferta:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="name"
                                onChange={this.handleChange}
                                required
                                value={this.state.newOffer.name}/>
                            <h4 className='admin__label'>Profesor:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="staff"
                                onChange={this.handleChange}
                                required
                                value={this.state.newOffer.staff}/>
                            <h4 className='admin__label'>Disciplina:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="discipline"
                                onChange={this.handleChange}
                                required
                                value={this.state.newOffer.discipline}/>
                                 <h4 className='admin__label'>Tip optional:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="optionalType"
                                onChange={this.handleChange}
                                required
                                value={this.state.newOffer.optionalType}/>
                                <h4 className='admin__label'>Clasa:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="classroom"
                                onChange={this.handleChange}
                                required
                                value={this.state.newOffer.classroom}/>
                                 <h4 className='admin__label'>An:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="year"
                                onChange={this.handleChange}
                                required
                                value={this.state.newOffer.year}/>
                            <button type='submit' className='admin__submit-button'> {this.state.newOffer.id
                        ? 'Editează'
                        : 'Adaugă'} oferta</button>       
                        </form>
                        <div className='admin__existing-documents'>
                        {this.state.existingOffers && this.state.existingOffers.length?  <h3 className='admin__no-documents'> Oferte existente:</h3> : ''} 
                        {this.state.existingOffers && this.state.existingOffers.length? 
                        <div className='admin__existing-docs-wrapper'>
                         {this.state.existingOffers.map(offer=>
                            ( <div className='admin__card'>
                                 <h3 className='admin__card-title'>{offer.name}</h3>
                                 <p className='admin__card-description'>Profesor: {offer.staff}</p>
                                 <p className='admin__card-description'>Disciplina: {offer.discipline}</p>
                                 <p className='admin__card-description'>Tip optional: {offer.optional_type}</p>
                                 <p className='admin__card-description'>Clasa: {offer.classroom}</p>
                                 <p className='admin__card-description'>An: {offer.year}</p>
                                 
                                 <div className='admin__actions'>
                                    <h4 className='admin__edit' onClick={event => this.setOfferForEdit(event, offer)} >Editează</h4>
                                    <h4 className='admin__delete'  onClick={() => this.openDeleteDialog(offer.id)}>Șterge</h4>
                                 </div>
                             </div>)
                         )}
                         </div>: 
                        <h3 className='admin__no-documents'> Nu sunt documente adăugate.</h3>
                    }
                       
                               
                        </div>
                </div>
                <NotificationSystem
                    ref="notificationSystem"
                    style={NotificationSystemStyle}
                    />
                 <DeletePUGCertificates
                    opened={this.state.deleteoffer.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur că doriti să ștergeti aceasta oferta?"
                    aditionalText="Dupa ștergere, oferta nu va mai putea fi recuperata."
                  />
            </div>
        )
    }
}
export default AdminOffer;