import React from 'react';
import './Homepage.scss';
import '../../resources/StandardStyles.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer.jsx';
import { Link } from 'react-router-dom';
import { projectService } from '../../services/ProjectService';
import { announcementService } from '../../services/AnnouncementsService';
import { Row, Col, Card } from 'antd';
import { Slider } from 'antd';
import photosJson from './photos.json'
const { Meta } = Card;

class Homepage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            pagination: '',
            announcements: [],
            latestAnnouncement: {},
            showDetails: {
                news: false,
                projects: false,
                opportunities: false
            },
            sliderPhotos: photosJson,
            currentSlide: 0,
            seeMore: false
        };
    }
    componentWillMount() {
        projectService
            .getAll()
            .then(jsonResponse => {
                console.log(jsonResponse);
                this.setAdmission(jsonResponse);
            })
            .catch(error => console.log(error));
        announcementService
            .getAll()
            .then(jsonResponse => {
                console.log(jsonResponse);
                this.setAnnouncements(jsonResponse);
            })
            .catch(error => console.log(error));
    }
    setAdmission = response => {
        let documents = { ...this.state.documents };
        let pagination = { ...this.state.pagination };
        documents = response.projects;
        pagination = response.pagination;
        this.setState({ documents });
        this.setState({ pagination });
        console.log(this.state.documents);
    };
    setAnnouncements = response => {
        let announcements = { ...this.state.announcements }
        announcements = response.announcements;
        this.setState({ announcements })
        let latestAnnouncement = { ...this.state.latestAnnouncement };
        latestAnnouncement = announcements[this.state.announcements.length - 1];
        this.setState({ latestAnnouncement })
        console.log(latestAnnouncement.document.url)
    }
    scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    toggleDetails = (section) => {
        this.setState(prevState => ({
            showDetails: {
                ...prevState.showDetails,
                [section]: !prevState.showDetails[section]
            }
        }));
    };

    onChange = value => {
        this.setState({ currentSlide: value });
    };
    toggleSeeMore = () => {
        this.setState(prevState => ({
            seeMore: !prevState.seeMore
        }));
    };
    scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    render() {
        const showDetails = this.state.showDetails;
        const isMobile = window.innerWidth < 768;
        return (
            <div className='standard'>
                <Header />
                <div className='homepage__banner'>
                    {/* <img src='/images/presentation/img-1.jpg'alt='school' className="homepage__school-img"/> */}
                    <h4 className='homepage__motto'>
                        “Podoabele nu fac frumos, ci mai frumos. Învățătura nu te face înțelept, ci mai înțelept.”
                    </h4>
                    <h5 className='homepage__motto-author'>Nicolae Iorga</h5>
                </div>

                <div className='homepage__middle-part'>
                    <div>
                        <h3 className='homepage__section-title homepage__about-title'>Despre noi</h3>

                        <h4 className='homepage__middle-part-info'>Şcoala cu clasele I-VIII ,,Dimitrie Vatamaniuc’’ Suceviţa este singura şcoală din satul nostru, sat binecunoscut în întreaga ţară şi chiar pe plan mondial pentru Mănăstirea ce sălăsluieşte aici de mai bine de patru veacuri.</h4>
                        <h4 className='homepage__middle-part-info'>Nu este o școală foarte mare, nici ca spațiu,  nici ca număr de elevi, dar este generoasă din alte puncte de vedere.</h4>
                        <h4 className='homepage__middle-part-info'>Învățământul se desfășoară pe un singur schimb, respectiv dimineața, în 25 săli de clasă dintre care două sunt și laboratoare IT.  Beneficiem de apă curentă, de încălzire centrală și de grupuri sanitare moderne, iar elevii și cadrele didactice au acces la o bibliotecă destul de bine înzestrată. </h4>
                        <h5 className='homepage__see-details' onClick={this.toggleSeeMore}> {this.state.seeMore ? '' : 'Vezi mai mult'}</h5>
                        {this.state.seeMore ? <h4 className='homepage__middle-part-info'>Învățământul se desfășoară pe un singur schimb, respectiv dimineața, în 25 săli de clasă dintre care două sunt și laboratoare IT.  Beneficiem de apă curentă, de încălzire centrală și de grupuri sanitare moderne, iar elevii și cadrele didactice au acces la o bibliotecă destul de bine înzestrată. </h4> : ''}
                        <h5 className='homepage__see-details' onClick={this.toggleSeeMore}> {this.state.seeMore ? 'Vezi mai puțin' : ''}</h5>
                    </div>

                    <div className='homepage__slider'>

                        <div style={{ textAlign: 'center' }}>
                            <img src={this.state.sliderPhotos[this.state.currentSlide].img} alt={`Slide ${this.state.currentSlide}`} style={{ maxWidth: '100%', height: isMobile ? '300px' : '500px', borderRadius: '10px' }} />
                        </div>
                        <Slider
                            defaultValue={0}
                            onChange={this.onChange}
                            min={0}
                            dots={true}
                            autoAdjustOverflow={false}
                            max={this.state.sliderPhotos.length - 1}
                        />
                    </div>

                </div>
                <div className='homepage__vision-part'>
                    <h3 className='homepage__section-title homepage__about-title'>Viziunea școlii noastre</h3>
                    <h4 className='homepage__middle-part-info'>Ţelul nostru este să pregătim generaţii de tineri activi şi creativi, cu gândire flexibilă,
                        cu abilităţi de comunicare interculturală şi cu competenţe antreprenoriale care să contribuie
                        la integrarea lor cu succes în viaţa comunităţii şi la opţiunea învăţării pe tot parcursul vieţii.</h4>
                    <h4 className='homepage__middle-part-info'>Şcoala nu mai poate continua să fie ruptă de realitate, ea trebuie să devină mediul cel
                        mai propice de pregătire pentru viaţă, locul unde elevul să se simtă confortabil, pentru a-şi
                        putea dezvolta potenţialul la maxim.</h4>
                    <img src='/images/icons/pen.svg' alt='pen' className='homepage__pen-icon' />
                </div>
                <div className='homepage__discover-section'>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={8}>
                            <Link onClick={this.scrollToTop} to='/noutati' className='standard__link'>
                                <Card
                                    hoverable
                                    cover={<img alt="projects" src={showDetails.news ? "/images/presentation/projects1-dark.png" : "/images/presentation/projects1.png"} />}
                                    onMouseEnter={() => this.toggleDetails('news')}
                                    onMouseLeave={() => this.toggleDetails('news')}
                                >
                                    {showDetails.news && (
                                        <Meta
                                            title="Noutati"
                                            description={<a href="/projects">Cititi despre noutatile noastre</a>}
                                        />
                                    )}
                                </Card>
                            </Link>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Link onClick={this.scrollToTop} to='/proiecte' className='standard__link'>
                                <Card
                                    hoverable
                                    cover={<img alt="projects" src={showDetails.projects ? "/images/presentation/projects2-dark.png" : "/images/presentation/projects2.png"} />}
                                    onMouseEnter={() => this.toggleDetails('projects')}
                                    onMouseLeave={() => this.toggleDetails('projects')}
                                >
                                    {showDetails.projects && (
                                        <Meta
                                            title="Proiecte"
                                            description={<a href="/projects">Informații despre proiectele noastre</a>}
                                        />
                                    )}
                                </Card>
                            </Link>
                        </Col>
                        <Col xs={24} sm={24} md={8}>
                            <Link onClick={this.scrollToTop} to='/noutati' className='standard__link'>
                                <Card
                                    hoverable
                                    cover={<img alt="opportunities" src={showDetails.opportunities ? "/images/presentation/projects3-dark.png" : "/images/presentation/projects3.png"} />}
                                    onMouseEnter={() => this.toggleDetails('opportunities')}
                                    onMouseLeave={() => this.toggleDetails('opportunities')}
                                >
                                    {showDetails.opportunities && (
                                        <Meta
                                            title="Oportunități"
                                            description={<a href="/opportunities">Descoperiți oportunitățile pe care le oferim</a>}
                                        />
                                    )}
                                </Card>
                            </Link>

                        </Col>
                    </Row>
                </div>
                <div className='homepage__latest-news-part'>
                    <h2 className='homepage__latest-news-subtitle'>Ultimele noutăți ale școlii</h2>
                    <div className='homepage__line' />
                    {this.state.latestAnnouncement && (
                      <div className='homepage__latest-news-div'>
                          <img src={this.state.latestAnnouncement && this.state.latestAnnouncement.document ? process.env.REACT_APP_API_URL + this.state.latestAnnouncement.document.url : ''} className='homepage__latest-news-img' alt='latest-news-image' />
                          <div className='homepage__latest-news'>
                              <h3 className='homepage__latest-news-title'>{this.state.latestAnnouncement.title}</h3>
                              <h4 className='standard__general-description' id='homepage-description'>{this.state.latestAnnouncement.description}</h4>
                              <div className='homepage__latest-news-buttons'>
                                  <button className='homepage__standard-button'>
                                      <h5 className='homepage__standard-button-writing'>CITESTE MAI MULTE</h5>
                                  </button>

                              </div>
                          </div>
                      </div>
                    )}
                </div>




                <Footer />
            </div>
        )
    }
}

export default Homepage;
