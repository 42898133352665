
import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const wealthDeclarationService = {
  getAll,
  createOrUpdate,
  deleteMember
};
const requestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL}/api/wealth_declarations/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions)
    .then(responseHandleService.handleResponse)
    .catch(error => console.log(error));
}
function createOrUpdate(newMember) {
  const requestOptions = {
    method: newMember.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newMember)
  };

  let authentication = new AuthenticationService();

  console.log(authentication.loggedIn());

  if (authentication.loggedIn()) {
    requestOptions.headers['Authorization'] =authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/wealth_declarations${
    newMember.id ? `/${newMember.id}` : ''
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}
function createBodyRequest(member) {
  let formData = new FormData();

  formData.append('wealth_declaration[name]', member.name);
  formData.append('wealth_declaration[position]', member.position);
  formData.append('wealth_declaration[year]', member.year);
  const document = member.document;

  if (document) {
    if (document.url) {
      formData.append(`wealth_declaration[document]`, document.url);
    } else {
      formData.append(`wealth_declaration[document]`, document, document.name);
    }
  }

  return formData;
}
function deleteMember(memberId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =this.authentication.getToken();
  }
  let url = `${process.env.REACT_APP_API_URL}/api/wealth_declarations/${memberId}`;

  return axios.delete(url, requestOptionsDelete);
}
