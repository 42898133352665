import React from 'react';
import AdminMenu from '../../../components/AdminMenu/AdminMenu';
import AttachementField from '../../../components/AttachementField/AttachementField';
import '../../../resources/adminStyle.scss';
import {resultService} from '../../../services/ResultService';
import NotificationSystem from 'react-notification-system';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AuthenticationService from '../../../services/AuthenticationService';

class AdminResults extends React.Component{
    constructor(props){
        super(props)
        this.state={
           newResult:{
            title:'',
            date:'',
            description:'',
            document:''
           },
           existingResults:[],
           deleteResult: {
            openedDialog: false,
            deleteId: 0
          },
          pagination: {}
        }
        this.authentication = new AuthenticationService();
    }
    handleChange=event=>{
        let newResult = { ...this.state.newResult };
        newResult[event.target.name] = event.target.value;
  
          this.setState({ newResult });
      }
    
      componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        resultService
        .getAll()
        .then(response => {
          this.setResultState(response);
        })
        .catch(error => {
          console.log(error);
        });
      }
      setResultState = response => {
        let existingResults = this.state.existingResults;
        existingResults = response.results;
        this.setState({
            existingResults
        });
        let pagination = { ...this.state.pagination };
        pagination = response.pagination;
        this.setState({
          pagination
        });
      };
      handlePageClick = currentPage => {
        resultService
          .getAll(currentPage.selected + 1)
          .then(response => {
            this.setResultState(response);
          })
          .catch(error => console.log(error));
      };
      resetForm = () => {
        let newResult = { ...this.state.newResult };
        newResult = {
          title: '',
          date:'',
          description:'',
          document: ''
        };
        this.setState({ newResult });
        this.setState({ disabledDocument: false });
      };
      openDeleteDialog = id => {
        let deleteResult = { ...this.state.deleteResult };
        deleteResult = {
          openedDialog: true,
          deleteId: id
        };
    
        this.setState({ deleteResult });
      };
    
      closeDeleteDialog = () => {
        let deleteResult = { ...this.state.deleteResult };
        deleteResult = {
          openedDialog: false,
          deleteId: 0
        };
    
        this.setState({ deleteResult });
      };
      handleFormSubmit = event => {
        event.preventDefault();
    
        resultService
          .createOrUpdate(this.state.newResult)
          .then(response => {
    
            let existingResults = this.state.existingResults;
    
            let index = existingResults.findIndex(
              existingResults => existingResults.id === response.id
            );
    
            if (index !== -1) {
              existingResults[index] = response;
            } else {
              existingResults.push(response);
            }
    
            this.setState({
              existingResults
            });
    
            this._notificationSystem.addNotification({
              message: `Rezultatul a fost ${
                this.state.newResult.id ? 'editat' : 'adaugat'
              } cu success.`,
              level: 'success',
              position: 'tc'
            });

            this.resetForm();
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problemă la adăugare, faceti refresh la pagina sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
      onDropDocument = documents => {
        let newResult = { ...this.state.newResult };
        let reader = new FileReader();
        let self = this;
    
        reader.onload = function(upload) {
          newResult.document = documents[0];
    
          self.setState({ newResult });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };
    
      removeDocument = () => {
        let newResult = { ...this.state.newResult };
        newResult.document = null;
    
        this.setState({ newResult });
        this.setState({ disabledDocument: false });
      };
    
      setResultForEdit = (event, result) => {
          event.preventDefault();

        let newResult = { ...this.state.newResult };
        newResult = Object.assign({}, result);
        this.setState({ newResult });
    
        if (newResult.document && newResult.document.url) {
          this.setState({ disabledDocument: true });
        }
      };
    
      handleDelete = () => {
        resultService
          .deleteResult(this.state.deleteResult.deleteId)
          .then(() => {
            let existingResults = this.state.existingResults.slice();
            existingResults = existingResults.filter(
                result => result.id !== this.state.deleteResult.deleteId
            );
            this.setState({ existingResults });
    
            this.closeDeleteDialog();
    
            this._notificationSystem.addNotification({
              message: 'Rezultatul a fost șters cu success.',
              level: 'success',
              position: 'tc'
            });
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problema la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
    render(){
        return(
            <div className='admin'>
                <AdminMenu/>
                <div className='admin__wrapper'>
                    <h2 className='admin__title'>Rezultate</h2>
                    <h3 className='admin__subtitle'>Adaugă un rezultat nou:</h3>
                        <form className='admin__form'onSubmit={this.handleFormSubmit}>
                            <h4 className='admin__label'>Titlu:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="title"
                                onChange={this.handleChange}
                                required
                                value={this.state.newResult.title}/>
                            <h4 className='admin__label'>Dată:</h4>
                            <input
                                className="admin__field"
                                type="date"
                                name="date"
                                required
                                onChange={this.handleChange}
                                value={this.state.newResult.date}/>
                             <h4 className='admin__label'>Detaliere (optional):</h4>
                            <textarea
                                rows='5'
                                cols='10'
                                className="admin__field admin__textarea"
                                type="text"
                                name="description"
                                onChange={this.handleChange}
                                value={this.state.newResult.description}/>
                            <div className='admin__attachement'>
                                <AttachementField
                                onDrop={this.onDropDocument}
                                removeDocument={this.removeDocument}
                                document={this.state.newResult.document}
                                documentFilename={
                                    this.state.newResult.document_filename
                                }
                                type="image/*"
                                />
                            </div>
                            <button type='submit' className='admin__submit-button'> {this.state.newResult.id
                        ? 'Editează'
                        : 'Adaugă'}  rezultat:</button>       
                        </form>
                        <div className='admin__existing-documents'>
                        {this.state.existingResults && this.state.existingResults.length?  <h3 className='admin__no-documents'> Documente existente:</h3> : ''} 
                        {this.state.existingResults && this.state.existingResults.length? 
                        <div className='admin__existing-docs-wrapper'>
                         {this.state.existingResults.map(result=>
                            ( <div className='admin__card'>
                                 <h3 className='admin__card-title'>{result.title}</h3>
                                 <div className='admin__date-box'>
                                    <img src='/images/icons/calendar.png' alt='calendar'/>
                                    <h4 className='admin__card-date'>{result.date}</h4>
                                 </div>
                                 <p className='admin__card-description'>{result.description}</p>
                                 <div className='admin__document-box'  onClick={() =>
                                    window.open(
                                        `${process.env.REACT_APP_API_URL}${
                                        result.document.url
                                        }`
                                    )
                                    }>
                                     <img src='/images/icons/file.png' alt='file'/>
                                     <h3 className='admin__document-name'>Vezi document</h3>
                                 </div>
                                 <div className='admin__actions'>
                                    <h4 className='admin__edit' onClick={event => this.setResultForEdit(event, result)} >Editează</h4>
                                    <h4 className='admin__delete'  onClick={() => this.openDeleteDialog(result.id)}>Șterge</h4>
                                 </div>
                             </div>)
                         )}
                         </div>: 
                        <h3 className='admin__no-documents'> Nu sunt documente adăugate.</h3>
                    }
                       
                               
                        </div>
                </div>
                <NotificationSystem
                    ref="notificationSystem"
                    style={NotificationSystemStyle}
                    />
                 <DeletePUGCertificates
                    opened={this.state.deleteResult.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur că doriti să ștergeti acest rezultat?"
                    aditionalText="Dupa ștergere, rezultatul nu va mai putea fi recuperat."
                  />
            </div>
        )
    }
}
export default AdminResults;