import React from 'react';
import AdminMenu from '../../../components/AdminMenu/AdminMenu';
import AttachementField from '../../../components/AttachementField/AttachementField';
import '../../../resources/adminStyle.scss';
import {regulamentService} from '../../../services/RegulamentService';
import NotificationSystem from 'react-notification-system';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AuthenticationService from '../../../services/AuthenticationService';

class Regulaments extends React.Component{
    constructor(props){
        super(props)
        this.state={
           newRegulament:{
            title:'',
            date:'',
            document:''
           },
           existingRegulaments:[],
           deleteRegulament: {
            openedDialog: false,
            deleteId: 0
          },
          pagination: {}
        }
        this.authentication = new AuthenticationService();
    }
    handleChange=event=>{
        let newRegulament = { ...this.state.newRegulament };
        newRegulament[event.target.name] = event.target.value;
  
          this.setState({ newRegulament });
      }
   
    
      componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        regulamentService
        .getAll()
        .then(response => {
          this.setRegulamentState(response);
        })
        .catch(error => {
          console.log(error);
        });
      }
      setRegulamentState = response => {
        let existingRegulaments = this.state.existingRegulaments;
        existingRegulaments = response.regulaments;
        this.setState({
            existingRegulaments
        });
        let pagination = { ...this.state.pagination };
        pagination = response.pagination;
        this.setState({
          pagination
        });
      };
      handlePageClick = currentPage => {
        regulamentService
          .getAll(currentPage.selected + 1)
          .then(response => {
            this.setRegulamentState(response);
          })
          .catch(error => console.log(error));
      };
      resetForm = () => {
        let newRegulament = { ...this.state.newRegulament };
        newRegulament = {
          title: '',
          date:'',
          description:'',
          document: ''
        };
        this.setState({ newRegulament });
        this.setState({ disabledDocument: false });
      };
      openDeleteDialog = id => {
        let deleteRegulament = { ...this.state.deleteRegulament };
        deleteRegulament = {
          openedDialog: true,
          deleteId: id
        };
    
        this.setState({ deleteRegulament });
      };
    
      closeDeleteDialog = () => {
        let deleteRegulament = { ...this.state.deleteRegulament };
        deleteRegulament = {
          openedDialog: false,
          deleteId: 0
        };
    
        this.setState({ deleteRegulament });
      };
      handleFormSubmit = event => {
        event.preventDefault();
    
        regulamentService
          .createOrUpdate(this.state.newRegulament)
          .then(response => {
    
            let existingRegulaments = this.state.existingRegulaments;
    
            let index = existingRegulaments.findIndex(
              existingRegulaments => existingRegulaments.id === response.id
            );
    
            if (index !== -1) {
              existingRegulaments[index] = response;
            } else {
              existingRegulaments.push(response);
            }
    
            this.setState({
              existingRegulaments
            });
    
            this._notificationSystem.addNotification({
              message: `Regulamentul a fost ${
                this.state.newRegulament.id ? 'editat' : 'adaugat'
              } cu success.`,
              level: 'success',
              position: 'tc'
            });

            this.resetForm();
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problemă la adăugare, faceti refresh la pagina sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
      onDropDocument = documents => {
        let newRegulament = { ...this.state.newRegulament };
        let reader = new FileReader();
        let self = this;
    
        reader.onload = function(upload) {
          newRegulament.document = documents[0];
    
          self.setState({ newRegulament });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };
    
      removeDocument = () => {
        let newRegulament = { ...this.state.newRegulament };
        newRegulament.document = null;
    
        this.setState({ newRegulament });
        this.setState({ disabledDocument: false });
      };
    
      setRegulamentForEdit = (event, regulament) => {
          event.preventDefault();

        let newRegulament = { ...this.state.newRegulament };
        newRegulament = Object.assign({}, regulament);
        this.setState({ newRegulament });
    
        if (newRegulament.document && newRegulament.document.url) {
          this.setState({ disabledDocument: true });
        }
      };
    
      handleDelete = () => {
        regulamentService
          .deleteRegulament(this.state.deleteRegulament.deleteId)
          .then(() => {
            let existingRegulaments = this.state.existingRegulaments.slice();
            existingRegulaments = existingRegulaments.filter(
                regulament => regulament.id !== this.state.deleteRegulament.deleteId
            );
            this.setState({ existingRegulaments });
    
            this.closeDeleteDialog();
    
            this._notificationSystem.addNotification({
              message: 'Regulamentul a fost șters cu success.',
              level: 'success',
              position: 'tc'
            });
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problema la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
    render(){
        return(
            <div className='admin'>
                <AdminMenu/>
                <div className='admin__wrapper'>
                    <h2 className='admin__title'>Regulamente</h2>
                    <h3 className='admin__subtitle'>Adaugă un regulament:</h3>
                        <form className='admin__form' onSubmit={this.handleFormSubmit}>
                            <h4 className='admin__label'>Titlu document:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="title"
                                onChange={this.handleChange}
                                required
                                value={this.state.newRegulament.title}/>
                            <h4 className='admin__label'>Data:</h4>
                            <input
                                className="admin__field"
                                type="date"
                                name="date"
                                required
                                onChange={this.handleChange}
                                value={this.state.newRegulament.date}/>
                            <div className='admin__attachement'>
                                <AttachementField
                                onDrop={this.onDropDocument}
                                removeDocument={this.removeDocument}
                                document={this.state.newRegulament.document}
                                documentFilename={
                                    this.state.newRegulament.document_filename
                                }
                                type="image/*"
                                />
                            </div>
                            <button type='submit' className='admin__submit-button'> {this.state.newRegulament.id
                        ? 'Editează'
                        : 'Adaugă'} regulamentul</button>       
                        </form>
                        <div className='admin__existing-documents'>
                        {this.state.existingRegulaments && this.state.existingRegulaments.length?  <h3 className='admin__no-documents'> Documente existente:</h3> : ''} 
                        {this.state.existingRegulaments && this.state.existingRegulaments.length? 
                        <div className='admin__existing-docs-wrapper'>
                         {this.state.existingRegulaments.map(regulament=>
                            ( <div className='admin__card'>
                                 <h3 className='admin__card-title'>{regulament.title}</h3>
                                 <div className='admin__date-box'>
                                    <img src='/images/icons/calendar.png' alt='calendar'/>
                                    <h4 className='admin__card-date'>{regulament.date}</h4>
                                 </div>
                                 <p className='admin__card-description'>{regulament.description}</p>
                                 <div className='admin__document-box'  onClick={() =>
                                    window.open(
                                        `${process.env.REACT_APP_API_URL}${
                                        regulament.document.url
                                        }`
                                    )
                                    }>
                                     <img src='/images/icons/file.png' alt='file'/>
                                     <h3 className='admin__document-name'>Vezi document</h3>
                                 </div>
                                 <div className='admin__actions'>
                                    <h4 className='admin__edit' onClick={event => this.setRegulamentForEdit(event, regulament)} >Editează</h4>
                                    <h4 className='admin__delete'  onClick={() => this.openDeleteDialog(regulament.id)}>Șterge</h4>
                                 </div>
                             </div>)
                         )}
                         </div>: 
                        <h3 className='admin__no-documents'> Nu sunt documente adăugate.</h3>
                    }
                       
                               
                        </div>
                </div>
                <NotificationSystem
                    ref="notificationSystem"
                    style={NotificationSystemStyle}
                    />
                 <DeletePUGCertificates
                    opened={this.state.deleteRegulament.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur că doriti să ștergeti acest regulament?"
                    aditionalText="Dupa ștergere, regulamentul nu va mai putea fi recuperat."
                  />
            </div>
        )
    }
}
export default Regulaments;