import React from 'react';
import AdminMenu from '../../../components/AdminMenu/AdminMenu';
import AttachementField from '../../../components/AttachementField/AttachementField';
import '../../../resources/adminStyle.scss';
import {parentsCouncilService} from '../../../services/ParentsCouncilService';
import NotificationSystem from 'react-notification-system';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AuthenticationService from '../../../services/AuthenticationService';

class AdminParentsCouncil extends React.Component{
    constructor(props){
        super(props)
        this.state={
           newMember:{
            name:'',
            position:'',
            document:'',
            documentDecision:''
           },
           existingMembers:[],
           deleteMember: {
            openedDialog: false,
            deleteId: 0
          },
          pagination: {}
        }
        this.authentication = new AuthenticationService();
    }
    handleChange=event=>{
        let newMember = { ...this.state.newMember };
        newMember[event.target.name] = event.target.value;
  
          this.setState({ newMember });
      }
    
      componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        parentsCouncilService
        .getAll()
        .then(response => {
          this.setMemberState(response);
        })
        .catch(error => {
          console.log(error);
        });
      }
      setMemberState = response => {
        let existingMembers = this.state.existingMembers;
        existingMembers = response.parents_boards;
        this.setState({
            existingMembers
        });
        let pagination = { ...this.state.pagination };
        pagination = response.pagination;
        this.setState({
          pagination
        });
      };
      handlePageClick = currentPage => {
        parentsCouncilService
          .getAll(currentPage.selected + 1)
          .then(response => {
            this.setMemberState(response);
          })
          .catch(error => console.log(error));
      };
      resetForm = () => {
        let newMember = { ...this.state.newMember };
        newMember = {
          name: '',
          position:'',
          documentDecision:'',
          document: ''
        };
        this.setState({ newMember });
        this.setState({ disabledDocument: false });
      };
      openDeleteDialog = id => {
        let deleteMember = { ...this.state.deleteMember };
        deleteMember = {
          openedDialog: true,
          deleteId: id
        };
    
        this.setState({ deleteMember });
      };
    
      closeDeleteDialog = () => {
        let deleteMember = { ...this.state.deleteMember };
        deleteMember = {
          openedDialog: false,
          deleteId: 0
        };
    
        this.setState({ deleteMember });
      };
      handleFormSubmit = event => {
        event.preventDefault();
    
        parentsCouncilService
          .createOrUpdate(this.state.newMember)
          .then(response => {
    
            let existingMembers = this.state.existingMembers;
    
            let index = existingMembers.findIndex(
              existingMembers => existingMembers.id === response.id
            );
    
            if (index !== -1) {
              existingMembers[index] = response;
            } else {
              existingMembers.push(response);
            }
    
            this.setState({
              existingMembers
            });
    
            this._notificationSystem.addNotification({
              message: `Membrul a fost ${
                this.state.newMember.id ? 'editat' : 'adaugat'
              } cu success.`,
              level: 'success',
              position: 'tc'
            });

            this.resetForm();
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problemă la adăugare, faceti refresh la pagina sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
      onDropDocument = documents => {
        let newMember = { ...this.state.newMember };
        let reader = new FileReader();
        let self = this;
    
        reader.onload = function(upload) {
          newMember.document = documents[0];
    
          self.setState({ newMember });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };
    
      removeDocument = () => {
        let newMember = { ...this.state.newMember };
        newMember.document = null;
    
        this.setState({ newMember });
        this.setState({ disabledDocument: false });
      };
      onDropDecisionDocument = documents => {
        let newMember = { ...this.state.newMember };
        let reader = new FileReader();
        let self = this;
    
        reader.onload = function(upload) {
          newMember.documentDecision = documents[0];
    
          self.setState({ newMember });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };
    
      removeDecisionDocument = () => {
        let newMember = { ...this.state.newMember };
        newMember.documentDecision = null;
    
        this.setState({ newMember });
        this.setState({ disabledDocument: false });
      };
    
      setMemberForEdit = (event, member) => {
          event.preventDefault();

        let newMember = { ...this.state.newMember };
        newMember = Object.assign({}, member);
        this.setState({ newMember });
    
        if (newMember.document && newMember.document.url) {
          this.setState({ disabledDocument: true });
        }
      };
    
      handleDelete = () => {
        parentsCouncilService
          .deleteMember(this.state.deleteMember.deleteId)
          .then(() => {
            let existingMembers = this.state.existingMembers.slice();
            existingMembers = existingMembers.filter(
                member => member.id !== this.state.deleteMember.deleteId
            );
            this.setState({ existingMembers });
    
            this.closeDeleteDialog();
    
            this._notificationSystem.addNotification({
              message: 'Membrul a fost sters cu success.',
              level: 'success',
              position: 'tc'
            });
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problema la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
    render(){
        return(
            <div className='admin'>
                <AdminMenu/>
                <div className='admin__wrapper'>
                    <h2 className='admin__title'>Consiliul Reprezentativ al Parintilor</h2>
                    <h3 className='admin__subtitle'>Adaugă membri consiliul parintilor:</h3>
                        <form className='admin__form' onSubmit={this.handleFormSubmit}>
                            <h4 className='admin__label'>Nume membru:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="name"
                                onChange={this.handleChange}
                                required
                                value={this.state.newMember.name}/>
                            <h4 className='admin__label'>Pozitie membru:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="position"
                                onChange={this.handleChange}
                                required
                                value={this.state.newMember.position}/>
                                <h4 className='admin__label'>Document membru:</h4>
                            <div className='admin__attachement'>
                                <AttachementField
                                onDrop={this.onDropDocument}
                                removeDocument={this.removeDocument}
                                document={this.state.newMember.document}
                                documentFilename={
                                    this.state.newMember.document_filename
                                }
                                type="image/*"
                                />
                            </div>
                            <h4 className='admin__label'>Document decizie membru:</h4>
                            <div className='admin__attachement'>
                                <AttachementField
                                onDrop={this.onDropDecisionDocument}
                                removeDocument={this.removeDecisionDocument}
                                document={this.state.newMember.documentDecision}
                                documentFilename={
                                    this.state.newMember.documentDecision_filename
                                }
                                type="image/*"
                                />
                            </div>
                            <button type='submit' className='admin__submit-button'> {this.state.newMember.id
                        ? 'Editează'
                        : 'Adaugă'} membru</button>       
                        </form>
                        <div className='admin__existing-documents'>
                        {this.state.existingMembers && this.state.existingMembers.length?  <h3 className='admin__no-documents'> Membri existenti:</h3> : ''} 
                        {this.state.existingMembers && this.state.existingMembers.length? 
                        <div className='admin__existing-docs-wrapper'>
                         {this.state.existingMembers.map(member=>
                            ( <div className='admin__card'>
                                 <h3 className='admin__card-title'>{member.name}</h3>
                                 <p className='admin__card-description'>Pozitie: {member.position}</p>
                                 <div className='admin__document-box'  onClick={() =>
                                    window.open(
                                        `${process.env.REACT_APP_API_URL}${
                                        member.document.url
                                        }`
                                    )
                                    }>
                                     <img src='/images/icons/file.png' alt='file'/>
                                     <h3 className='admin__document-name admin__first-doc'>Vezi document</h3>
                                 </div>
                                 <div className='admin__document-box'  onClick={() =>
                                    window.open(
                                        `${process.env.REACT_APP_API_URL}${
                                        member.documentDecision.url
                                        }`
                                    )
                                    }>
                                     <img src='/images/icons/file.png' alt='file'/>
                                     <h3 className='admin__document-name'>Vezi decizie</h3>
                                 </div>
                                 <div className='admin__actions'>
                                    <h4 className='admin__edit' onClick={event => this.setMemberForEdit(event, member)} >Editează</h4>
                                    <h4 className='admin__delete'  onClick={() => this.openDeleteDialog(member.id)}>Șterge</h4>
                                 </div>
                             </div>)
                         )}
                         </div>: 
                        <h3 className='admin__no-documents'> Nu sunt documente adăugate.</h3>
                    }
                       
                               
                        </div>
                </div>
                <NotificationSystem
                    ref="notificationSystem"
                    style={NotificationSystemStyle}
                    />
                 <DeletePUGCertificates
                    opened={this.state.deleteMember.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur că doriti să ștergeti acest membru?"
                    aditionalText="Dupa ștergere, membryl nu va mai putea fi recuperat."
                  />
            </div>
        )
    }
}
export default AdminParentsCouncil;