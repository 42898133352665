import React from 'react';
import './Values.scss';
import valuesJson from './Values.json';
import '../../resources/StandardStyles.scss';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header';

class Values extends React.Component{
    constructor(props){
        super(props)
        this.state={
            values:valuesJson
        }
    }
    render(){
        return(
            <div className='standard values'>
            <Header/>
                <div className='standard__first-section'>
                    <h2 className='standard__general-title'>Valorile noastre</h2>
                </div>
                <div className='values__container'>
                    <h3 className='standard__section-title'>Valorile scolii noastre</h3>
                    {
                        this.state.values.map(value=>(
                            <ul className='values__list'>
                                <div className='values__individual-value'>
                                    <h4 className='values__value-number'>{value.id}</h4>
                                    <li className='values__item' key={value.id}>{value.value}</li>
                                 </div>
                            </ul>
                        ))
                    }

                </div>
                <Footer/>
            </div>
        )
    }
}

export default Values;