import React from 'react';
import AdminMenu from '../../../components/AdminMenu/AdminMenu';
import AttachementField from '../../../components/AttachementField/AttachementField';
import '../../../resources/adminStyle.scss';
import {announcementService} from '../../../services/AnnouncementsService';
import NotificationSystem from 'react-notification-system';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AuthenticationService from '../../../services/AuthenticationService';

class Announcements extends React.Component{
    constructor(props){
        super(props)
        this.state={
           newAnnouncement:{
            title:'',
            date:'',
            description:'',
            document:''
           },
           existingAnnouncements:[],
           deleteAnnouncement: {
            openedDialog: false,
            deleteId: 0
          },
          pagination: {}
        }
        this.authentication = new AuthenticationService();
    }
    handleChange=event=>{
        let newAnnouncement = { ...this.state.newAnnouncement };
        newAnnouncement[event.target.name] = event.target.value;
  
          this.setState({ newAnnouncement });
      }

    
      componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        announcementService
          .getAll()
          .then(response => {
            this.setAnnouncementState(response);
          })
          .catch(error => {
            console.log(error);
          });
      }
      setAnnouncementState = response => {
        let existingAnnouncements = this.state.existingAnnouncements;
        existingAnnouncements = response.announcements;
        this.setState({
            existingAnnouncements
        });
        let pagination = { ...this.state.pagination };
        pagination = response.pagination;
        this.setState({
          pagination
        });
      };
      handlePageClick = currentPage => {
        announcementService
          .getAll(currentPage.selected + 1)
          .then(response => {
            this.setAnnouncementState(response);
          })
          .catch(error => console.log(error));
      };
      resetForm = () => {
        let newAnnouncement = { ...this.state.newAnnouncement };
        newAnnouncement = {
          title: '',
          date:'',
          description:'',
          document: ''
        };
        this.setState({ newAnnouncement });
        this.setState({ disabledDocument: false });
      };
      openDeleteDialog = id => {
        let deleteAnnouncement = { ...this.state.deleteAnnouncement };
        deleteAnnouncement = {
          openedDialog: true,
          deleteId: id
        };
    
        this.setState({ deleteAnnouncement });
      };
    
      closeDeleteDialog = () => {
        let deleteAnnouncement = { ...this.state.deleteAnnouncement };
        deleteAnnouncement = {
          openedDialog: false,
          deleteId: 0
        };
    
        this.setState({ deleteAnnouncement });
      };
      handleFormSubmit = event => {
        event.preventDefault();
    
        announcementService
          .createOrUpdate(this.state.newAnnouncement)
          .then(response => {
    
            let existingAnnouncements = this.state.existingAnnouncements;
    
            let index = existingAnnouncements.findIndex(
              existingAnnouncements => existingAnnouncements.id === response.id
            );
    
            if (index !== -1) {
              existingAnnouncements[index] = response;
            } else {
              existingAnnouncements.push(response);
            }
    
            this.setState({
              existingAnnouncements
            });
    
            this._notificationSystem.addNotification({
              message: `Anunțul a fost ${
                this.state.newAnnouncement.id ? 'editat' : 'adaugat'
              } cu success.`,
              level: 'success',
              position: 'tc'
            });

            this.resetForm();
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problemă la adăugare, faceti refresh la pagina sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
      onDropDocument = documents => {
        let newAnnouncement = { ...this.state.newAnnouncement };
        let reader = new FileReader();
        let self = this;
    
        reader.onload = function(upload) {
          newAnnouncement.document = documents[0];
    
          self.setState({ newAnnouncement });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };
    
      removeDocument = () => {
        let newAnnouncement = { ...this.state.newAnnouncement };
        newAnnouncement.document = null;
    
        this.setState({ newAnnouncement });
        this.setState({ disabledDocument: false });
      };
    
      setAnnouncementForEdit = (event, announcement) => {
          event.preventDefault();

        let newAnnouncement = { ...this.state.newAnnouncement };
        newAnnouncement = Object.assign({}, announcement);
        this.setState({ newAnnouncement });
    
        if (newAnnouncement.document && newAnnouncement.document.url) {
          this.setState({ disabledDocument: true });
        }
      };
    
      handleDelete = () => {
        announcementService
          .deleteAnnouncement(this.state.deleteAnnouncement.deleteId)
          .then(() => {
            let existingAnnouncements = this.state.existingAnnouncements.slice();
            existingAnnouncements = existingAnnouncements.filter(
                announcement => announcement.id !== this.state.deleteAnnouncement.deleteId
            );
            this.setState({ existingAnnouncements });
    
            this.closeDeleteDialog();
    
            this._notificationSystem.addNotification({
              message: 'Anunțul a fost șters cu success.',
              level: 'success',
              position: 'tc'
            });
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problema la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
  
    render(){
        return(
            <div className='admin'>
                <AdminMenu/>
                <div className='admin__wrapper'>
                    <h2 className='admin__title'>Anunțuri</h2>
                    <h3 className='admin__subtitle'>Adaugă un anunț nou:</h3>
                        <form className='admin__form' onSubmit={this.handleFormSubmit}>
                            <h4 className='admin__label'>Titlu:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="title"
                                onChange={this.handleChange}
                                required
                                value={this.state.newAnnouncement.title}/>
                            <h4 className='admin__label'>Dată:</h4>
                            <input
                                className="admin__field"
                                type="date"
                                name="date"
                                required
                                onChange={this.handleChange}
                                value={this.state.newAnnouncement.date}/>
                             <h4 className='admin__label'>Detaliere:</h4>
                            <textarea
                                rows='5'
                                cols='10'
                                className="admin__field admin__textarea"
                                type="text"
                                name="description"
                                onChange={this.handleChange}
                                value={this.state.newAnnouncement.description}/>
                            <div className='admin__attachement'>
                                <AttachementField
                                onDrop={this.onDropDocument}
                                removeDocument={this.removeDocument}
                                document={this.state.newAnnouncement.document}
                                documentFilename={
                                    this.state.newAnnouncement.document_filename
                                }
                                type="image/*"
                                />
                            </div>
                            <button type='submit' className='admin__submit-button'> {this.state.newAnnouncement.id
                        ? 'Editează'
                        : 'Adaugă'} anunț:</button>       
                        </form>
                        <div className='admin__existing-documents'>
                        {this.state.existingAnnouncements && this.state.existingAnnouncements.length?  <h3 className='admin__no-documents'> Documente existente:</h3> : ''} 
                        {this.state.existingAnnouncements && this.state.existingAnnouncements.length? 
                        <div className='admin__existing-docs-wrapper'>
                         {this.state.existingAnnouncements.map(announcement=>
                            ( <div className='admin__card'>
                                 <h3 className='admin__card-title'>{announcement.title}</h3>
                                 <div className='admin__date-box'>
                                    <img src='/images/icons/calendar.png' alt='calendar'/>
                                    <h4 className='admin__card-date'>{announcement.date}</h4>
                                 </div>
                                 <p className='admin__card-description'>{announcement.description}</p>
                                 <div className='admin__document-box'  onClick={() =>
                                    window.open(
                                        `${process.env.REACT_APP_API_URL}${
                                        announcement.document.url
                                        }`
                                    )
                                    }>
                                     <img src='/images/icons/file.png' alt='file'/>
                                     <h3 className='admin__document-name'>Vezi document</h3>
                                 </div>
                                 <div className='admin__actions'>
                                    <h4 className='admin__edit' onClick={event => this.setAnnouncementForEdit(event, announcement)} >Editează</h4>
                                    <h4 className='admin__delete'  onClick={() => this.openDeleteDialog(announcement.id)}>Șterge</h4>
                                 </div>
                             </div>)
                         )}
                         </div>: 
                        <h3 className='admin__no-documents'> Nu sunt documente adăugate.</h3>
                    }
                       
                               
                        </div>
                </div>
                <NotificationSystem
                    ref="notificationSystem"
                    style={NotificationSystemStyle}
                    />
                 <DeletePUGCertificates
                    opened={this.state.deleteAnnouncement.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur că doriti să ștergeti acest anunt?"
                    aditionalText="Dupa ștergere, anuntul nu va mai putea fi recuperat."
                  />
            </div>
        )
    }
}
export default Announcements;