// @flow

import React, { Component } from 'react';
import './SubMenu.scss';
import { Link } from 'react-router-dom';

class SubMenu extends Component {
  render() {
    return (
      <div
        className={
          'submenu__wrapper ' +
          (this.props.buttonHovered === this.props.menu.id ? 'active' : '')
        }
      >
        {this.props.menu.submenu &&
            <div className="submenu__column">
              <ul className="submenu__links">
                {this.props.menu.submenu.map(link =>
                    link.target ? (
                      <li key={link.id} className="submenu__link">
                        <a
                          onClick={() => {
                            this.props.closeMenu();
                            window.open(link.url, '_blank');
                          }}
                          className="title-item-asd"
                        >
                          {link.name}
                        </a>
                      </li>
                    ) : (
                      <li key={link.id} className="submenu__link">
                        <Link
                          to={link.url}
                          onClick={() => this.props.closeMenu()}
                        >
                          <span> {link.name} </span>
                        </Link>
                      </li>
                    )
                  )}
              </ul>
            </div>
          }
      </div>
    );
  }
}

export default SubMenu;
