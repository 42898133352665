import React from 'react';
import '../../resources/StandardStyles.scss';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Modal from 'react-modal';
import { bool, func, object } from 'prop-types';

const customStyles = {
    overlay: {
      zIndex: 12,
      backgroundColor: 'rgba(13, 8, 32, 0.9)',
      overflow: 'scroll'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      height: '80%'
    }
  };
  
class AddNote extends React.Component{
    constructor(props){
        super(props);

        this.state={
            grade:"",
            description:""
        }
    }
    handleChange=event=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    }
    render(){
        return(
            <Modal
            isOpen={this.props.modalIsOpen}
            ariaHideApp={false}
            onRequestClose={this.props.closeModal}
            style={customStyles}
            contentLabel="Event Modal"
          >
            <div className='standard standard__grade-action-container login'>
                <div className='login__container standard__grade-container'>
                    <img className='standard__close-icon' src='/images/icons/close.svg' alt='close' onClick={() => this.props.closeModal()}/>
                    <h3 className='standard__section-title login__title'>Adaugă Nota</h3>
                    <form className='login__form'>
                        <div className='standard__add-grade'>
                            <FormControl className="solicitation-modal__status">
                                <NativeSelect>
                                    <option value="name">Elev</option>
                                    <option>1</option>
                                    <option>2</option>
                                </NativeSelect>
                            </FormControl>

                            <input 
                            className='login__input standard__grade-input'
                            type="number" 
                            name="grade"
                            onChange={this.handleChange}
                            required
                            value={this.state.grade}
                            placeholder="Nota"/>
                        </div>
                        <textarea cols="30"
                            rows="1"
                            type='text'
                            className='login__input'
                            placeholder='Descriere'
                            onChange={this.handleChange}
                            name="description"
                            value={this.state.description}
                            onChange={this.handleChange}
                            />
                            <button type='submit' className='standard__add-note-button standard__add-note-modal'>
                                <h5 className='standard__add-note'>ADAUGĂ NOTA</h5>
                            </button>
                    </form>
                </div>
        </div>
        </Modal>
        )
    }
}
AddNote.propTypes = {
    modalIsOpen: bool.isRequired,
    closeModal: func.isRequired,
    eventObject: object.isRequired
  };
export default AddNote;