import React from 'react';
import './SpecificClass.scss';
import '../../resources/StandardStyles.scss';
import specificClass from './specificClass.json';
import AddNoteModal from '../AddNoteModal/AddNoteModal'
import EditNoteModal from '../EditNoteModal/EditNoteModal';

class SpecificClass extends React.Component{
    constructor(props){
        super(props)
        this.state={
            studentGrades:specificClass,
            addNoteModalOpen:false,
            AddNoteModal:{},
            editNoteModalOpen:false,
            EditNoteModal:{}
        }
    }

    toggleGradesBox = currentGrade => {
        let grades = this.state.studentGrades;
    
        grades.map(grade => {
          if (grade.id === currentGrade.id) {
            grade.openedBox = !grade.openedBox;
          }
        });
    
        this.setState({
            grades
        });
      };

      openAddNoteModal=addNote=>{
        this.setState({addNoteModalOpen:true})
        this.setState({ AddNoteModal: addNote });
      }
      openEditNoteModal=editNote=>{
        this.setState({editNoteModalOpen:true})
        this.setState({ EditNoteModal: editNote });
      }
      closeModal = () => {
        this.setState({ addNoteModalOpen: false });
        this.setState({ editNoteModalOpen: false });
      };
    render(){
        return(
            <div className='sc'>
                <div className='sc__note-add'>
                    <h3 className='sc__title'>Clasa aVa</h3>
                    <button className='standard__add-note-button'onClick={() => this.openAddNoteModal()}><h5 className='standard__add-note'>ADAUGĂ NOTA</h5></button>
                </div>
                <AddNoteModal
                    eventObject={this.state.AddNoteModal}
                    modalIsOpen={this.state. addNoteModalOpen}
                    closeModal={this.closeModal}
                 />
                {this.state.studentGrades.map(item=>(
                    <div className='sc__map-div'>
                        <div className='sc__student-name-box'onClick={event =>this.toggleGradesBox(item)}>
                            <h3 className='sc__student-name'>{item.student}</h3>
                            <img className='sc__down-arrow' src={item.openedBox? '/images/icons/down-arrow.svg' : '/images/icons/up-arrow.svg'} alt='arrow'/>
                        </div>
                        <table className={"sc__table "+(item.openedBox? '':'sc__display-closed')}>
                            <thead>
                                <tr>
                                    <td
                                        className="sc__table-title"
                                    >
                                        Nota
                                    </td>
                                    <td
                                        className="sc__table-title"
                                    >
                                        Date
                                    </td>
                                    <td
                                        className="sc__table-title"
                                    >
                                        Detalii
                                    </td>
                                    <td
                                        className="sc__table-title"
                                    >
                                        Editeaza
                                    </td>
                                </tr>
                            </thead>
                            {item.grades.map(gradeItem=>(
                                <tbody>
                                    <tr>
                                    <td className='sc__row'>
                                        <h4 className="sc__info">{gradeItem.note}</h4>
                                    </td>
                                    <td className='sc__row'> 
                                        <h4 className="sc__info">{gradeItem.date}</h4>
                                    </td>
                                    <td className='sc__row'>
                                        <h4 className="sc__info">{gradeItem.details}</h4>
                                    </td>
                                    <td className='sc__row'>
                                        <img src='/images/icons/edit.svg' className='sc__edit-icon' alt='edit' onClick={()=>this.openEditNoteModal()}/>
                                    </td>
                    
                                    </tr>
                                </tbody>
                                    ))}
                 <EditNoteModal
                    eventObject={this.state.EditNoteModal}
                    modalIsOpen={this.state.editNoteModalOpen}
                    closeModal={this.closeModal}
                 />
                        </table>

                    </div>
                ))}

            </div>
        )
    }
}

export default SpecificClass;