import React from 'react';
import AdminMenu from '../../../components/AdminMenu/AdminMenu';
import '../../../resources/adminStyle.scss';
import {linksService} from '../../../services/LinksService';
import NotificationSystem from 'react-notification-system';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AuthenticationService from '../../../services/AuthenticationService';

class UsefulLinks extends React.Component{
    constructor(props){
        super(props)
        this.state={
           newLink:{
            title:'',
            link:'',
            description:''
           },
           existingLinks:[],
           deleteLink: {
            openedDialog: false,
            deleteId: 0
          },
          pagination: {}
        }
        this.authentication = new AuthenticationService();
    }
    handleChange=event=>{
        let newLink = { ...this.state.newLink };
        newLink[event.target.name] = event.target.value;
  
          this.setState({ newLink });
      }

    
      componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
        linksService
        .getAll()
        .then(response => {
          this.setLinkState(response);
        })
        .catch(error => {
          console.log(error);
        });
      }
      setLinkState = response => {
        let existingLinks = this.state.existingLinks;
        existingLinks = response.usefull_links;
        this.setState({
            existingLinks
        });
        let pagination = { ...this.state.pagination };
        pagination = response.pagination;
        this.setState({
          pagination
        });
      };
      handlePageClick = currentPage => {
        linksService
          .getAll(currentPage.selected + 1)
          .then(response => {
            this.setLinkState(response);
          })
          .catch(error => console.log(error));
      };
      resetForm = () => {
        let newLink = { ...this.state.newLink };
        newLink = {
          title: '',
          link:'',
          description:'',
          document: ''
        };
        this.setState({ newLink });
        this.setState({ disabledDocument: false });
      };
      openDeleteDialog = id => {
        let deleteLink = { ...this.state.deleteLink };
        deleteLink = {
          openedDialog: true,
          deleteId: id
        };
    
        this.setState({ deleteLink });
      };
    
      closeDeleteDialog = () => {
        let deleteLink = { ...this.state.deleteLink };
        deleteLink = {
          openedDialog: false,
          deleteId: 0
        };
    
        this.setState({ deleteLink });
      };
      handleFormSubmit = event => {
        event.preventDefault();
    
        linksService
          .createOrUpdate(this.state.newLink)
          .then(response => {
    
            let existingLinks = this.state.existingLinks;
    
            let index = existingLinks.findIndex(
              existingLinks => existingLinks.id === response.id
            );
    
            if (index !== -1) {
              existingLinks[index] = response;
            } else {
              existingLinks.push(response);
            }
    
            this.setState({
              existingLinks
            });
    
            this._notificationSystem.addNotification({
              message: `Link-ul a fost ${
                this.state.newLink.id ? 'editat' : 'adaugat'
              } cu success.`,
              level: 'success',
              position: 'tc'
            });

            this.resetForm();
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problemă la adăugare, faceti refresh la pagina sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
      onDropDocument = documents => {
        let newLink = { ...this.state.newLink };
        let reader = new FileReader();
        let self = this;
    
        reader.onload = function(upload) {
          newLink.document = documents[0];
    
          self.setState({ newLink });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };
    
      removeDocument = () => {
        let newLink = { ...this.state.newLink };
        newLink.document = null;
    
        this.setState({ newLink });
        this.setState({ disabledDocument: false });
      };
    
      setLinkForEdit = (event, link) => {
          event.preventDefault();

        let newLink = { ...this.state.newLink };
        newLink = Object.assign({}, link);
        this.setState({ newLink });
    
        if (newLink.document && newLink.document.url) {
          this.setState({ disabledDocument: true });
        }
      };
    
      handleDelete = () => {
        linksService
          .deleteLink(this.state.deleteLink.deleteId)
          .then(() => {
            let existingLinks = this.state.existingLinks.slice();
            existingLinks = existingLinks.filter(
                link => link.id !== this.state.deleteLink.deleteId
            );
            this.setState({ existingLinks });
    
            this.closeDeleteDialog();
    
            this._notificationSystem.addNotification({
              message: 'Link-ul a fost șters cu success.',
              level: 'success',
              position: 'tc'
            });
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problema la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
  
    render(){
        return(
            <div className='admin'>
                <AdminMenu/>
                <div className='admin__wrapper'>
                    <h2 className='admin__title'>Link-uri utile</h2>
                    <h3 className='admin__subtitle'>Adaugă un link nou:</h3>
                        <form className='admin__form' onSubmit={this.handleFormSubmit}>
                            <h4 className='admin__label'>Titlu link:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="title"
                                required
                                onChange={this.handleChange}
                                value={this.state.newLink.title}/>
                            <h4 className='admin__label'>Adresă link:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="link"
                                required
                                onChange={this.handleChange}
                                value={this.state.newLink.link}
                                placeholder='Ex: www.link-util.ro'/>
                            <h4 className='admin__label'>Descriere (opțional):</h4>
                            <textarea
                                rows='5'
                                cols='10'
                                className="admin__field admin__textarea"
                                type="text"
                                name="description"
                                onChange={this.handleChange}
                                value={this.state.newLink.description}/>
                          
                          <button type='submit' className='admin__submit-button'> {this.state.newLink.id
                        ? 'Editează'
                        : 'Adaugă'}  link-ul</button>       
                        </form>
                        <div className='admin__existing-documents'>
                        {this.state.existingLinks && this.state.existingLinks.length?  <h3 className='admin__no-documents'> Documente existente:</h3> : ''} 
                        {this.state.existingLinks && this.state.existingLinks.length? 
                        <div className='admin__existing-docs-wrapper'>
                         {this.state.existingLinks.map(link=>
                            ( <div className='admin__card'>
                                 <h3 className='admin__card-title'>{link.title}</h3>
                                 <div className='admin__date-box'>
                                    <img src='/images/icons/link-blue.png' alt='link'/>
                                    <h4 className='admin__card-date'>{link.link}</h4>
                                 </div>
                                 <p className='admin__card-description'>{link.description}</p>
                                 <div className='admin__actions'>
                                    <h4 className='admin__edit' onClick={event => this.setLinkForEdit(event, link)} >Editează</h4>
                                    <h4 className='admin__delete'  onClick={() => this.openDeleteDialog(link.id)}>Șterge</h4>
                                 </div>
                             </div>)
                         )}
                         </div>: 
                        <h3 className='admin__no-documents'> Nu sunt documente adăugate.</h3>
                    }
                       
                               
                        </div>
                </div>
                <NotificationSystem
                    ref="notificationSystem"
                    style={NotificationSystemStyle}
                    />
                 <DeletePUGCertificates
                    opened={this.state.deleteLink.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur că doriti să ștergeti acest proiect?"
                    aditionalText="Dupa ștergere, proiectul nu va mai putea fi recuperat."
                  />
                </div>
        )
    }
}
export default UsefulLinks;