import React from 'react';
import './News.scss';
import '../../resources/StandardStyles.scss';
import Footer from '../../components/Footer/Footer.jsx';
import Header from '../../components/Header/Header';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import { announcementService } from '../../services/AnnouncementsService';

class News extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            announcements: []
        }
    }
    componentWillMount() {
        announcementService
            .getAll()
            .then(jsonResponse => {
                console.log(jsonResponse);
                this.setAnnouncements(jsonResponse);
            })
            .catch(error => console.log(error));
    }
    setAnnouncements = response => {
        let announcements = { ...this.state.announcements }
        announcements = response.announcements;
        this.setState({ announcements })
        let latestAnnouncement = { ...this.state.latestAnnouncement };
        latestAnnouncement = announcements[this.state.announcements.length - 1];
        this.setState({ latestAnnouncement })
        console.log(latestAnnouncement.document.url)
    }
    render() {
        return (
            <div className='standard news'>
                <Header />
                <div className='standard__first-section'>
                    <h2 className='standard__general-title'>Noutățile școlii</h2>
                    <div className='standard__general-line'/>
                </div>

                    <div className='news__container'>

                            {this.state.announcements.map(news => (
                                <div key={news.id} className='news__individual'>
                                    <img className='news__image' src={news.document ? process.env.REACT_APP_API_URL + news.document.url : '/images/presentation/announcement.png'} alt='project-image' />
                                    <h3 className='news__title'>{news.title}</h3>
                                    <h5 className='news__date'>{news.date}</h5>
                                    <h4 className='news__description'>{news.description}</h4>
                                </div>
                            ))}



                    </div>
                    <Footer />
                </div>
                )
    };
}

                export default News;