import React from 'react';
import AdminMenu from '../../../components/AdminMenu/AdminMenu';
import AttachementField from '../../../components/AttachementField/AttachementField';
import '../../../resources/adminStyle.scss';
import {projectService} from '../../../services/ProjectService';
import NotificationSystem from 'react-notification-system';
import DeletePUGCertificates from '../../../components/DeleteDialog/DeleteDialog';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AuthenticationService from '../../../services/AuthenticationService';

class AdminProjects extends React.Component{
    constructor(props){
        super(props)
        this.state={
           newProject:{
            title:'',
            date:'',
            description:'',
            document:''
           },
           existingProjects:[],
           deleteProject: {
            openedDialog: false,
            deleteId: 0
          },
          pagination: {}
        }
        this.authentication = new AuthenticationService();
    }
    handleChange=event=>{
        let newProject = { ...this.state.newProject };
        newProject[event.target.name] = event.target.value;
  
          this.setState({ newProject });
      }
      componentWillMount() {
        projectService
          .getAll()
          .then(response => {
            this.setProjectState(response);
          })
          .catch(error => {
            console.log(error);
          });
      }
    
      componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
      }
      setProjectState = response => {
        let existingProjects = this.state.existingProjects;
        existingProjects = response.projects;
        this.setState({
            existingProjects
        });
        let pagination = { ...this.state.pagination };
        pagination = response.pagination;
        this.setState({
          pagination
        });
      };
      handlePageClick = currentPage => {
        projectService
          .getAll(currentPage.selected + 1)
          .then(response => {
            this.setProjectState(response);
          })
          .catch(error => console.log(error));
      };
      resetForm = () => {
        let newProject = { ...this.state.newProject };
        newProject = {
          title: '',
          date:'',
          description:'',
          document: ''
        };
        this.setState({ newProject });
        this.setState({ disabledDocument: false });
      };
      openDeleteDialog = id => {
        let deleteProject = { ...this.state.deleteProject };
        deleteProject = {
          openedDialog: true,
          deleteId: id
        };
    
        this.setState({ deleteProject });
      };
    
      closeDeleteDialog = () => {
        let deleteProject = { ...this.state.deleteProject };
        deleteProject = {
          openedDialog: false,
          deleteId: 0
        };
    
        this.setState({ deleteProject });
      };
      handleFormSubmit = event => {
        event.preventDefault();
    
        projectService
          .createOrUpdate(this.state.newProject)
          .then(response => {
    
            let existingProjects = this.state.existingProjects;
    
            let index = existingProjects.findIndex(
              existingProjects => existingProjects.id === response.id
            );
    
            if (index !== -1) {
              existingProjects[index] = response;
            } else {
              existingProjects.push(response);
            }
    
            this.setState({
              existingProjects
            });
    
            this._notificationSystem.addNotification({
              message: `Proiectul a fost ${
                this.state.newProject.id ? 'editat' : 'adaugat'
              } cu success.`,
              level: 'success',
              position: 'tc'
            });

            this.resetForm();
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problemă la adăugare, faceti refresh la pagina sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
      onDropDocument = documents => {
        let newProject = { ...this.state.newProject };
        let reader = new FileReader();
        let self = this;
    
        reader.onload = function(upload) {
          newProject.document = documents[0];
    
          self.setState({ newProject });
          self.setState({ disabledDocument: true });
        };
        reader.readAsDataURL(documents[0]);
      };
    
      removeDocument = () => {
        let newProject = { ...this.state.newProject };
        newProject.document = null;
    
        this.setState({ newProject });
        this.setState({ disabledDocument: false });
      };
    
      setProjectForEdit = (event, project) => {
          event.preventDefault();

        let newProject = { ...this.state.newProject };
        newProject = Object.assign({}, project);
        this.setState({ newProject });
    
        if (newProject.document && newProject.document.url) {
          this.setState({ disabledDocument: true });
        }
      };
    
      handleDelete = () => {
        projectService
          .deleteProject(this.state.deleteProject.deleteId)
          .then(() => {
            let existingProjects = this.state.existingProjects.slice();
            existingProjects = existingProjects.filter(
                project => project.id !== this.state.deleteProject.deleteId
            );
            this.setState({ existingProjects });
    
            this.closeDeleteDialog();
    
            this._notificationSystem.addNotification({
              message: 'Proiectul a fost șters cu success.',
              level: 'success',
              position: 'tc'
            });
          })
          .catch(error => {
            this._notificationSystem.addNotification({
              message:
                'A aparut o problema la ștergere, faceți refresh la pagină sau contactați administratorul dacă problema persistă.',
              level: 'error',
              position: 'tc'
            });
          });
      };
    render(){
        return(
            <div className='admin'>
                <AdminMenu/>
                <div className='admin__wrapper'>
                    <h2 className='admin__title'>Proiecte</h2>
                    <h3 className='admin__subtitle'>Adaugă un proiect nou:</h3>
                        <form className='admin__form' onSubmit={this.handleFormSubmit}>
                            <h4 className='admin__label'>Titlu:</h4>
                            <input
                                className="admin__field"
                                type="text"
                                name="title"
                                onChange={this.handleChange}
                                required
                                value={this.state.newProject.title}/>
                            <h4 className='admin__label'>Dată:</h4>
                            <input
                                className="admin__field"
                                type="date"
                                name="date"
                                required
                                onChange={this.handleChange}
                                value={this.state.newProject.date}/>
                             <h4 className='admin__label'>Scurtă descriere (opțional):</h4>
                            <textarea
                                rows='5'
                                cols='10'
                                className="admin__field admin__textarea"
                                type="text"
                                name="description"
                                onChange={this.handleChange}
                                value={this.state.newProject.description}/>
                            <div className='admin__attachement'>
                                <AttachementField
                                onDrop={this.onDropDocument}
                                removeDocument={this.removeDocument}
                                document={this.state.newProject.document}
                                documentFilename={
                                    this.state.newProject.document_filename
                                }
                                type="image/*"
                                />
                            </div>
                            <button type='submit' className='admin__submit-button'> {this.state.newProject.id
                        ? 'Editează'
                        : 'Adaugă'}  proiect:</button>       
                        </form>
                        <div className='admin__existing-documents'>
                        {this.state.existingProjects && this.state.existingProjects.length?  <h3 className='admin__no-documents'> Documente existente:</h3> : ''} 
                        {this.state.existingProjects && this.state.existingProjects.length? 
                        <div className='admin__existing-docs-wrapper'>
                         {this.state.existingProjects.map(project=>
                            ( <div className='admin__card'>
                                 <h3 className='admin__card-title'>{project.title}</h3>
                                 <div className='admin__date-box'>
                                    <img src='/images/icons/calendar.png' alt='calendar'/>
                                    <h4 className='admin__card-date'>{project.date}</h4>
                                 </div>
                                 <p className='admin__card-description'>{project.description}</p>
                                 <div className='admin__document-box'  onClick={() =>
                                    window.open(
                                        `${process.env.REACT_APP_API_URL}${
                                        project.document.url
                                        }`
                                    )
                                    }>
                                     <img src='/images/icons/file.png' alt='file'/>
                                     <h3 className='admin__document-name'>Vezi document</h3>
                                 </div>
                                 <div className='admin__actions'>
                                    <h4 className='admin__edit' onClick={event => this.setProjectForEdit(event, project)} >Editează</h4>
                                    <h4 className='admin__delete'  onClick={() => this.openDeleteDialog(project.id)}>Șterge</h4>
                                 </div>
                             </div>)
                         )}
                         </div>: 
                        <h3 className='admin__no-documents'> Nu sunt documente adăugate.</h3>
                    }
                       
                               
                        </div>
                </div>
                <NotificationSystem
                    ref="notificationSystem"
                    style={NotificationSystemStyle}
                    />
                 <DeletePUGCertificates
                    opened={this.state.deleteProject.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteți sigur că doriti să ștergeti acest proiect?"
                    aditionalText="Dupa ștergere, proiectul nu va mai putea fi recuperat."
                  />
            </div>
        )
    }
}
export default AdminProjects;